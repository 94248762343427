// 
// _hero.scss
//

.hero-section {
    position: relative;
    padding: 80px 0 120px 0;

    &:after {
        content: ' ';
        position: absolute;
        top: -400px;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        border-radius: 0;
        transform: skewY(-3deg);
        background-size: cover;
        background-position: center;
        filter: drop-shadow(0 0 8px rgba(0,0,0, 0.75));
    }

    .hero-title {
        line-height: 42px;
    }
}