/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 3.4.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./config/variables";

@import "./config/light-mode";
@import "./config/dark-mode";
@import "./config/custom-variables";

@import "./node_modules/bootstrap/scss/bootstrap";

// Structure
@import "./custom/structure/general";
@import "./custom/structure/left-menu";
@import "./custom/structure/topbar";
@import "./custom/structure/right-sidebar";
@import "./custom/structure/page-head";
@import "./custom/structure/footer";
// horizontal nav
@import "./custom/structure/horizontal-nav";

//Fonts
@import "./custom/fonts/nunito";

//Components
@import "./custom/components/mixins";
@import "./custom/components/accordions";
@import "./custom/components/alert";
@import "./custom/components/avatar";
@import "./custom/components/breadcrumb";
@import "./custom/components/buttons";
@import "./custom/components/badge";
@import "./custom/components/card";
@import "./custom/components/custom-forms";
@import "./custom/components/dropdown";
@import "./custom/components/docs";
@import "./custom/components/forms";
@import "./custom/components/modal";
@import "./custom/components/nav";
@import "./custom/components/pagination";
@import "./custom/components/popover";
@import "./custom/components/print";
@import "./custom/components/progress";
@import "./custom/components/reboot";
@import "./custom/components/ribbons";
@import "./custom/components/switch";
@import "./custom/components/tables";
@import "./custom/components/type";
@import "./custom/components/utilities";
@import "./custom/components/widgets";
@import "./custom/components/social";
@import "./custom/components/steps";
@import "./custom/components/preloader";
@import "./custom/components/hero";

@import "./custom/pages/authentication";

// Vendors
@import "./vendor/daterangepicker";
@import "./vendor/bootstrap-datepicker.min";
@import "./vendor/jquery.toast.min";
@import "./vendor/select2.min";
@import "./vendor/jquery.toast.min";
@import "./vendor/bootstrap-timepicker.min";
@import "./vendor/jquery.bootstrap-touchspin.min";
