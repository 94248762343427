:root {
  --ct-link-color: #212245;
  --ct-link-hover-color: #1c1d3b;
  --ct-border-color: #dee2e6;
  --ct-box-shadow: 0px 0px 35px 0px #9aa1ab26;
  --ct-box-shadow-sm: 0 .125rem .25rem #00000013;
  --ct-box-shadow-lg: 0 0 45px 0 #0000001f;
  --ct-box-shadow-inset: inset 0 1px 2px #00000013;
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #212245;
  --ct-text-muted: #98a6ad;
  --ct-blockquote-footer-color: #98a6ad;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #6c757d;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #6c757d;
  --ct-table-striped-bg: #f1f3facc;
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: #dee2e666;
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #f1f3fa;
  --ct-table-border-color: #eef2f7;
  --ct-table-group-separator-color: #edeff1;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: #21224540;
  --ct-btn-active-box-shadow: inset 0 3px 5px #00000020;
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #adb5bd;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #fff;
  --ct-input-disabled-bg: #eef2f7;
  --ct-input-color: #6c757d;
  --ct-input-border-color: #dee2e6;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: var(--ct-input-bg);
  --ct-input-focus-border-color: #c8cbcf;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #adb5bd;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: #fff;
  --ct-form-check-input-border: 1px solid #dee2e6;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-form-switch-color: #dee2e6;
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #eef2f7;
  --ct-input-group-addon-border-color: #dee2e6;
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #98a6ad;
  --ct-form-select-disabled-bg: #eef2f7;
  --ct-form-select-disabled-border-color: ;
  --ct-form-select-indicator-color: #343a40;
  --ct-form-range-track-bg: #dee2e6;
  --ct-form-range-track-box-shadow: var(--ct-box-shadow-inset);
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem #0000001a;
  --ct-form-range-thumb-active-bg: #bcbdc7;
  --ct-form-range-thumb-disabled-bg: #adb5bd;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #eef2f7;
  --ct-form-file-button-hover-bg: #e2e6eb;
  --ct-nav-link-disabled-color: #98a6ad;
  --ct-nav-tabs-border-color: #dee2e6;
  --ct-nav-tabs-link-hover-border-color: #eef2f7 #eef2f7 var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #565e64;
  --ct-nav-tabs-link-active-bg: #fff;
  --ct-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: #ffffff8c;
  --ct-navbar-dark-hover-color: #ffffffbf;
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: #ffffff40;
  --ct-navbar-dark-toggler-border-color: #ffffff1a;
  --ct-navbar-light-color: #676f77;
  --ct-navbar-light-hover-color: #343a40;
  --ct-navbar-light-active-color: #000000e6;
  --ct-navbar-light-disabled-color: #adb5bd;
  --ct-dropdown-color: #6c757d;
  --ct-dropdown-bg: #fff;
  --ct-dropdown-border-color: #e7ebf0;
  --ct-dropdown-divider-bg: #e7ebf0;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #6c757d;
  --ct-dropdown-link-hover-color: #2c343f;
  --ct-dropdown-link-hover-bg: #f4f6fb;
  --ct-dropdown-link-active-color: #313a46;
  --ct-dropdown-link-active-bg: #f2f5f9;
  --ct-dropdown-link-disabled-color: #98a6ad;
  --ct-dropdown-dark-color: #dee2e6;
  --ct-dropdown-dark-bg: #343a40;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: #ffffff26;
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #adb5bd;
  --ct-dropdown-dark-header-color: #adb5bd;
  --ct-pagination-color: #313a46;
  --ct-pagination-bg: #fff;
  --ct-pagination-border-color: #dee2e6;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #eef2f7;
  --ct-pagination-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #313a46;
  --ct-pagination-hover-bg: #eef2f7;
  --ct-pagination-hover-border-color: #dee2e6;
  --ct-pagination-disabled-color: #98a6ad;
  --ct-pagination-disabled-bg: #fff;
  --ct-pagination-disabled-border-color: #dee2e6;
  --ct-card-border-color: #00000020;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #fff;
  --ct-card-bg: #fff;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: #00000020;
  --ct-accordion-button-active-bg: #e9e9ec;
  --ct-accordion-button-active-color: #1e1f3e;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #f1f3fa;
  --ct-tooltip-bg: #343a40;
  --ct-popover-bg: #fff;
  --ct-popover-border-color: #dee2e6;
  --ct-popover-header-bg: #f1f3fa;
  --ct-popover-header-color: ;
  --ct-popover-body-color: #6c757d;
  --ct-popover-arrow-color: #fff;
  --ct-popover-arrow-outer-color: #ced4da;
  --ct-toast-background-color: #ffffffd9;
  --ct-toast-border-color: #313a461a;
  --ct-toast-header-background-color: #ffffffd9;
  --ct-toast-header-border-color: #313a460d;
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #fff;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: #313a46;
  --ct-modal-header-border-color: var(--ct-border-color);
  --ct-modal-footer-border-color: var(--ct-border-color);
  --ct-progress-bg: #eef2f7;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #212245;
  --ct-list-group-color: #313a46;
  --ct-list-group-bg: #fff;
  --ct-list-group-border-color: #eef2f7;
  --ct-list-group-hover-bg: #f1f3fa;
  --ct-list-group-disabled-color: #6c757d;
  --ct-list-group-disabled-bg: #f5f7fc;
  --ct-list-group-action-color: #6c757d;
  --ct-list-group-action-active-color: #6c757d;
  --ct-list-group-action-active-bg: #eef2f7;
  --ct-thumbnail-bg: #fff;
  --ct-thumbnail-border-color: #dee2e6;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #98a6ad;
  --ct-breadcrumb-divider-color: #ced4da;
  --ct-breadcrumb-active-color: #adb5bd;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #313a46;
  --ct-code-color: #39afd1;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #313a46;
  --ct-pre-color: ;
  --ct-bg-leftbar: #fff;
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #212245;
  --ct-menu-item-active: #212245;
  --ct-bg-topbar: #fff;
  --ct-bg-topbar-search: #f1f3fa;
  --ct-nav-user-bg-topbar: #3c4655;
  --ct-nav-user-border-topbar: #414d5d;
  --ct-bg-dark-topbar: #313a46;
  --ct-bg-dark-topbar-search: #3c4655;
  --ct-nav-user-bg-dark-topbar: #3c4655;
  --ct-nav-user-border-dark-topbar: #414d5d;
  --ct-rightbar-bg: #fff;
  --ct-rightbar-title-bg: #313a46;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #444e5a;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #37404a;
  --ct-bg-detached-leftbar: #fff;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
  --ct-bg-topnav: #313a46;
  --ct-boxed-layout-bg: #fff;
  --ct-help-box-light-bg: #ffffff12;
  --ct-help-box-dark-bg: #212245;
  --ct-nav-pills-bg: #eef2f7;
  --ct-custom-accordion-title-color: #313a46;
  --ct-dragula-bg: #f7f9fb;
  --ct-form-wizard-header-bg: #eef2f7;
  --ct-text-title-color: #6c757d;
  --ct-page-title-color: #6c757d;
  --ct-card-loader-bg: #313a46;
  --ct-chat-primary-user-bg: #fef5e4;
  --ct-chat-secondary-user-bg: #f1f3fa;
  --ct-auth-bg: #fff;
  --ct-apex-grid-color: #f9f9fd;
  --ct-hero-bg: linear-gradient(to bottom, #031525, #0698c8);
}

body[data-leftbar-theme="dark"] {
  --ct-bg-leftbar: #313a46;
  --ct-dark-menu-item: #8391a2;
  --ct-dark-menu-item-hover: #bccee4;
  --ct-dark-menu-item-active: #fff;
}

body[data-leftbar-theme="default"] {
  --ct-menu-item: #cedce4;
  --ct-menu-item-hover: #fff;
  --ct-menu-item-active: #fff;
}

body[data-layout-color="dark"] {
  --ct-body-bg: #343a40;
  --ct-body-color: #aab8c5;
  --ct-link-color: #212245;
  --ct-link-hover-color: #1c1d3b;
  --ct-border-color: #464f5b;
  --ct-box-shadow: 0px 0px 35px 0px #31394280;
  --ct-box-shadow-sm: 0 .125rem .25rem #00000013;
  --ct-box-shadow-lg: 0 0 45px 0 #0000001f;
  --ct-box-shadow-inset: inset 0 1px 2px #00000013;
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #212245;
  --ct-text-muted: #8391a2;
  --ct-blockquote-footer-color: #ced4da;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #aab8c5;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #aab8c5;
  --ct-table-striped-bg: #404954cc;
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: #464f5b66;
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #404954;
  --ct-table-border-color: #464f5b;
  --ct-table-group-separator-color: #515c69;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: #21224540;
  --ct-btn-active-box-shadow: inset 0 3px 5px #00000020;
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #aab8c5;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #404954;
  --ct-input-disabled-bg: #37404a;
  --ct-input-color: #e3eaef;
  --ct-input-border-color: #4a525d;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: #464f5b;
  --ct-input-focus-border-color: #555f6b;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #8391a2;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: transparent;
  --ct-form-check-input-border: 1px solid #4a525d;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-form-switch-color: tiny-color(#464f5b, 6%);
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #48515d;
  --ct-input-group-addon-border-color: var(--ct-input-border-color);
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #ced4da;
  --ct-form-select-disabled-bg: #37404a;
  --ct-form-select-disabled-border-color: ;
  --ct-form-select-indicator-color: #e3eaef;
  --ct-form-range-track-bg: #464f5b;
  --ct-form-range-track-box-shadow: inset 0 .25rem .25rem #0000001a;
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem #0000001a;
  --ct-form-range-thumb-active-bg: #bcbdc7;
  --ct-form-range-thumb-disabled-bg: #aab8c5;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #48515d;
  --ct-form-file-button-hover-bg: #434b56;
  --ct-nav-link-disabled-color: #ced4da;
  --ct-nav-tabs-border-color: #464f5b;
  --ct-nav-tabs-link-hover-border-color: #37404a #37404a var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #e3eaef;
  --ct-nav-tabs-link-active-bg: #4a525d;
  --ct-nav-tabs-link-active-border-color: #464f5b #464f5b var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: #ffffff8c;
  --ct-navbar-dark-hover-color: #ffffffbf;
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: #ffffff40;
  --ct-navbar-dark-toggler-border-color: #ffffff1a;
  --ct-navbar-light-color: #d3d7db;
  --ct-navbar-light-hover-color: #e3eaef;
  --ct-navbar-light-active-color: #ffffffe6;
  --ct-navbar-light-disabled-color: #aab8c5;
  --ct-dropdown-color: #aab8c5;
  --ct-dropdown-bg: #3b444e;
  --ct-dropdown-border-color: #434b55;
  --ct-dropdown-divider-bg: #4d5662;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #aab8c5;
  --ct-dropdown-link-hover-color: #d9d9d9;
  --ct-dropdown-link-hover-bg: #48515d;
  --ct-dropdown-link-active-color: #fff;
  --ct-dropdown-link-active-bg: #7a8089;
  --ct-dropdown-link-disabled-color: #ced4da;
  --ct-dropdown-dark-color: #464f5b;
  --ct-dropdown-dark-bg: #e3eaef;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: #ffffff26;
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #aab8c5;
  --ct-dropdown-dark-header-color: #aab8c5;
  --ct-pagination-color: #8391a2;
  --ct-pagination-bg: #3f4851;
  --ct-pagination-border-color: #464f5b;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #37404a;
  --ct-pagination-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #f1f1f1;
  --ct-pagination-hover-bg: #474f58;
  --ct-pagination-hover-border-color: #464f5b;
  --ct-pagination-disabled-color: #8391a2;
  --ct-pagination-disabled-bg: #4a555f;
  --ct-pagination-disabled-border-color: #4a555f;
  --ct-card-border-color: #00000020;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #464f5b;
  --ct-card-bg: #37404a;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: #00000020;
  --ct-accordion-button-active-bg: #e9e9ec;
  --ct-accordion-button-active-color: #1e1f3e;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #37404a;
  --ct-tooltip-bg: #dee2e6;
  --ct-popover-bg: #37404a;
  --ct-popover-border-color: #464f5b;
  --ct-popover-header-bg: #3c4651;
  --ct-popover-header-color: #dee2e6;
  --ct-popover-body-color: #dee2e6;
  --ct-popover-arrow-color: #37404a;
  --ct-popover-arrow-outer-color: #464f5b;
  --ct-toast-background-color: #404954;
  --ct-toast-border-color: #f1f1f11f;
  --ct-toast-header-background-color: #40495433;
  --ct-toast-header-border-color: #f1f1f10d;
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #3b444e;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: #aab8c5;
  --ct-modal-header-border-color: #515c69;
  --ct-modal-footer-border-color: #515c69;
  --ct-progress-bg: #464f5b;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #212245;
  --ct-list-group-color: #f1f1f1;
  --ct-list-group-bg: var(--ct-card-bg);
  --ct-list-group-border-color: #4d5662;
  --ct-list-group-hover-bg: #404954;
  --ct-list-group-disabled-color: #8391a2;
  --ct-list-group-disabled-bg: #404954;
  --ct-list-group-action-color: #aab8c5;
  --ct-list-group-action-active-color: #dee2e6;
  --ct-list-group-action-active-bg: #404954;
  --ct-thumbnail-bg: #464f5b;
  --ct-thumbnail-border-color: #464f5b;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #ced4da;
  --ct-breadcrumb-divider-color: #8391a2;
  --ct-breadcrumb-active-color: #aab8c5;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #e3eaef;
  --ct-code-color: #39afd1;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #f1f1f1;
  --ct-pre-color: #aab8c5;
  --ct-bg-leftbar: #3a444e;
  --ct-menu-item: #cedce4;
  --ct-menu-item-hover: #fff;
  --ct-menu-item-active: #fff;
  --ct-bg-topbar: #3a444e;
  --ct-bg-topbar-search: #464f5b;
  --ct-nav-user-bg-topbar: #45515d;
  --ct-nav-user-border-topbar: #4a5764;
  --ct-bg-dark-topbar: #3a444e;
  --ct-bg-dark-topbar-search: #464f5b;
  --ct-nav-user-bg-dark-topbar: #45515d;
  --ct-nav-user-border-dark-topbar: #4a5764;
  --ct-rightbar-bg: #37404a;
  --ct-rightbar-title-bg: #212245;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #292b56;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #000;
  --ct-bg-detached-leftbar: #37404a;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #8f75da 0, #727cf5 60%);
  --ct-bg-topnav: linear-gradient(to bottom, #8f75da, #727cf5);
  --ct-boxed-layout-bg: #3e4853;
  --ct-help-box-light-bg: #ffffff1a;
  --ct-help-box-dark-bg: #212245;
  --ct-nav-pills-bg: #404954;
  --ct-custom-accordion-title-color: #8391a2;
  --ct-dragula-bg: #404954;
  --ct-form-wizard-header-bg: #404954;
  --ct-text-title-color: #fff;
  --ct-page-title-color: #fff;
  --ct-card-loader-bg: #f1f1f1;
  --ct-chat-primary-user-bg: #404954;
  --ct-chat-secondary-user-bg: #404954;
  --ct-auth-bg: #404954;
  --ct-apex-grid-color: #404954;
  --ct-hero-bg: linear-gradient(to bottom, #697ded, #5e30c1);
}

body[data-layout-color="dark"][data-layout="detached"] {
  --ct-menu-item: #8391a2;
  --ct-menu-item-hover: #212245;
  --ct-menu-item-active: #212245;
}

body[data-leftbar-theme="light"] {
  --ct-bg-leftbar: #fff;
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #212245;
  --ct-menu-item-active: #212245;
}

:root {
  --bs-blue: #2c8ef8;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #fa5c7c;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffbc00;
  --bs-green: #0acf97;
  --bs-teal: #02a8b5;
  --bs-cyan: #39afd1;
  --bs-white: #fff;
  --bs-gray: #98a6ad;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f1f3fa;
  --bs-gray-200: #eef2f7;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #98a6ad;
  --bs-gray-700: #6c757d;
  --bs-gray-800: #343a40;
  --bs-gray-900: #313a46;
  --bs-primary: #212245;
  --bs-secondary: #6c757d;
  --bs-success: #0acf97;
  --bs-info: #39afd1;
  --bs-warning: #ffbc00;
  --bs-danger: #fa5c7c;
  --bs-light: #eef2f7;
  --bs-dark: #313a46;
  --bs-primary-rgb: 33, 34, 69;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 10, 207, 151;
  --bs-info-rgb: 57, 175, 209;
  --bs-warning-rgb: 255, 188, 0;
  --bs-danger-rgb: 250, 92, 124;
  --bs-light-rgb: 238, 242, 247;
  --bs-dark-rgb: 49, 58, 70;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 108, 117, 125;
  --bs-body-bg-rgb: 250, 251, 254;
  --bs-font-sans-serif: "Nunito", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: Nunito, sans-serif;
  --bs-body-font-size: .9rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #6c757d;
  --bs-body-bg: #fafbfe;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--ct-border-color);
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .25rem;
  --bs-border-radius-sm: .2rem;
  --bs-border-radius-lg: .3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: var(--ct-link-color);
  --bs-link-hover-color: var(--ct-link-hover-color);
  --bs-code-color: var(--ct-code-color);
  --bs-highlight-bg: var(--ct-mark-bg);
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top-style: solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .75rem;
  font-weight: 500;
  line-height: 1.1;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + .75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: .9375rem;
}

h6, .h6 {
  font-size: .75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .75rem;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--ct-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 87.5%;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--ct-kbd-color);
  background-color: var(--ct-kbd-bg);
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--ct-table-caption-color);
  text-align: left;
  padding-top: .95rem;
  padding-bottom: .95rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  text-transform: uppercase;
  font-size: .75rem;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: var(--ct-blockquote-footer-color);
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: .75rem;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--ct-thumbnail-bg);
  border: 1px solid var(--ct-thumbnail-border-color);
  max-width: 100%;
  height: auto;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .75rem;
  line-height: 1;
}

.figure-caption {
  color: var(--ct-figure-caption-color);
  font-size: .75rem;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 12px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .375rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .375rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .75rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .75rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 2.25rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 2.25rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 4.5rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .375rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .375rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .75rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .75rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .375rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .375rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .75rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .75rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .375rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .375rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .75rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .75rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .375rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .375rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .75rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .75rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .375rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .375rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .75rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .75rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

.table {
  --bs-table-color: var(--ct-table-color);
  --bs-table-bg: var(--ct-table-bg);
  --bs-table-border-color: var(--ct-table-border-color);
  --bs-table-accent-bg: var(--ct-table-accent-bg);
  --bs-table-striped-color: var(--ct-table-striped-color);
  --bs-table-striped-bg: var(--ct-table-striped-bg);
  --bs-table-active-color: var(--ct-table-active-color);
  --bs-table-active-bg: var(--ct-table-active-bg);
  --bs-table-hover-color: var(--ct-table-hover-color);
  --bs-table-hover-bg: var(--ct-table-hover-bg);
  width: 100%;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-bottom: 1.5rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .95rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid var(--ct-table-group-separator-color);
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #313a46;
  --bs-table-bg: #d3d3da;
  --bs-table-border-color: #c3c4cb;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #92969f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c7c8cf;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #313a46;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #d0d2d5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #9b9fa5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d5d6d9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #313a46;
  --bs-table-bg: #cef5ea;
  --bs-table-border-color: #bee2da;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #8faaa8;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c2e7de;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #313a46;
  --bs-table-bg: #d7eff6;
  --bs-table-border-color: #c6dde4;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #95a7b0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #cbe1e9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #313a46;
  --bs-table-bg: #fff2cc;
  --bs-table-border-color: #eae0bf;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ada896;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #f0e4c2;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #313a46;
  --bs-table-bg: #fedee5;
  --bs-table-border-color: #eaced5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ac9ca5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #efd2d9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #313a46;
  --bs-table-bg: #eef2f7;
  --bs-table-border-color: #dbe0e5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #a2a8b0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e0e4ea;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #313a46;
  --bs-table-border-color: #464e59;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #313a46;
  --bs-table-active-bg: #838990;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #404954;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.45rem + 1px);
  padding-bottom: calc(.45rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: calc(1.2501rem + .0012vw);
}

@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.251rem;
  }
}

.col-form-label-sm {
  padding-top: calc(.28rem + 1px);
  padding-bottom: calc(.28rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: var(--ct-form-text-color);
  margin-top: .25rem;
  font-size: .75rem;
}

.form-control {
  width: 100%;
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: .25rem;
  padding: .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--ct-input-focus-color);
  background-color: var(--ct-input-focus-bg);
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: var(--ct-input-placeholder-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--ct-input-disabled-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  margin: -.45rem -.9rem;
  color: var(--ct-form-file-button-color);
  background-color: var(--ct-form-file-button-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .9rem;
  padding: .45rem .9rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--ct-form-file-button-hover-bg);
}

.form-control-plaintext {
  width: 100%;
  color: var(--ct-input-plaintext-color);
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .45rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .56rem + 2px);
  border-radius: .2rem;
  padding: .28rem .8rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.28rem -.8rem;
  margin-inline-end: .8rem;
  padding: .28rem .8rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.251rem;
  }
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .9rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .56rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .9rem + 2px);
  padding: .45rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .25rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .25rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .56rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(.9rem - 3px);
  color: var(--ct-form-select-color);
  background-color: var(--ct-form-select-bg);
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .9rem center;
  background-repeat: no-repeat;
  background-size: 14px 10px;
  border-radius: .25rem;
  padding: .45rem 2.7rem .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .9rem;
}

.form-select:disabled {
  color: var(--ct-form-select-disabled-color);
  background-color: var(--ct-form-select-disabled-bg);
  border-color: var(--ct-form-select-disabled-border-color);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--ct-form-select-color);
}

.form-select-sm {
  border-radius: .2rem;
  padding-top: .28rem;
  padding-bottom: .28rem;
  padding-left: .8rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .3rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.251rem;
  }
}

.form-check {
  min-height: 1.35rem;
  margin-bottom: 0;
  padding-left: 1.612em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.612em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.612em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.612em;
}

.form-check-input {
  width: 1.112em;
  height: 1.112em;
  vertical-align: top;
  background-color: var(--ct-form-check-input-bg);
  border: var(--ct-form-check-input-border);
  appearance: none;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: .194em;
  transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--ct-form-check-input-checked-bg-color);
  border-color: var(--ct-form-check-input-checked-border-color);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--ct-component-active-bg);
  border-color: var(--ct-component-active-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238b96a0' opacity='0.5'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238b96a0' opacity='0.5'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--ct-component-active-bg);
  appearance: none;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: var(--ct-form-range-thumb-active-bg);
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: var(--ct-form-range-track-bg);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--ct-component-active-bg);
  appearance: none;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: var(--ct-form-range-thumb-active-bg);
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: var(--ct-form-range-track-bg);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--ct-form-range-thumb-disabled-bg);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--ct-form-range-thumb-disabled-bg);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .9rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .9rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 3;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  color: var(--ct-input-group-addon-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-input-group-addon-bg);
  border: 1px solid var(--ct-input-group-addon-border-color);
  border-radius: .25rem;
  align-items: center;
  padding: .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (min-width: 1200px) {
  .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
    font-size: 1.251rem;
  }
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .2rem;
  padding: .28rem .8rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3.6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback), .input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.valid-feedback {
  width: 100%;
  color: #0acf97;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #0acf97e6;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .4rem .8rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .225rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .45rem) calc(.75em + .45rem);
  border-color: #0acf97;
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .225rem) top calc(.375em + .225rem);
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #0acf97;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 14px 10px, calc(.75em + .45rem) calc(.75em + .45rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.9rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #0acf97;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #0acf97;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #0acf97;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #fa5c7c;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #fa5c7ce6;
  border-radius: .2rem;
  margin-top: .1rem;
  padding: .4rem .8rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .225rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .45rem) calc(.75em + .45rem);
  border-color: #fa5c7c;
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .225rem) top calc(.375em + .225rem);
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #fa5c7c;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 14px 10px, calc(.75em + .45rem) calc(.75em + .45rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.9rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #fa5c7c;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #fa5c7c;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fa5c7c;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: .9rem;
  --bs-btn-padding-y: .45rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: .9rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #6c757d;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .15rem;
  --bs-btn-box-shadow: 0px 2px 6px 0px;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212245;
  --bs-btn-border-color: #212245;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c1d3b;
  --bs-btn-hover-border-color: #1a1b37;
  --bs-btn-focus-shadow-rgb: 66, 67, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1b37;
  --bs-btn-active-border-color: #191a34;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212245;
  --bs-btn-disabled-border-color: #212245;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0acf97;
  --bs-btn-border-color: #0acf97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #09b080;
  --bs-btn-hover-border-color: #08a679;
  --bs-btn-focus-shadow-rgb: 47, 214, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #08a679;
  --bs-btn-active-border-color: #089b71;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0acf97;
  --bs-btn-disabled-border-color: #0acf97;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #39afd1;
  --bs-btn-border-color: #39afd1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3095b2;
  --bs-btn-hover-border-color: #2e8ca7;
  --bs-btn-focus-shadow-rgb: 87, 187, 216;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e8ca7;
  --bs-btn-active-border-color: #2b839d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #39afd1;
  --bs-btn-disabled-border-color: #39afd1;
}

.btn-warning {
  --bs-btn-color: #313a46;
  --bs-btn-bg: #ffbc00;
  --bs-btn-border-color: #ffbc00;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #ffc626;
  --bs-btn-hover-border-color: #ffc31a;
  --bs-btn-focus-shadow-rgb: 224, 169, 11;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #ffc933;
  --bs-btn-active-border-color: #ffc31a;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: #ffbc00;
  --bs-btn-disabled-border-color: #ffbc00;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fa5c7c;
  --bs-btn-border-color: #fa5c7c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d54e69;
  --bs-btn-hover-border-color: #c84a63;
  --bs-btn-focus-shadow-rgb: 251, 116, 144;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c84a63;
  --bs-btn-active-border-color: #bc455d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fa5c7c;
  --bs-btn-disabled-border-color: #fa5c7c;
}

.btn-light {
  --bs-btn-color: #313a46;
  --bs-btn-bg: #eef2f7;
  --bs-btn-border-color: #eef2f7;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #caced2;
  --bs-btn-hover-border-color: #bec2c6;
  --bs-btn-focus-shadow-rgb: 210, 214, 220;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #bec2c6;
  --bs-btn-active-border-color: #b3b6b9;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: #eef2f7;
  --bs-btn-disabled-border-color: #eef2f7;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #313a46;
  --bs-btn-border-color: #313a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #505862;
  --bs-btn-hover-border-color: #464e59;
  --bs-btn-focus-shadow-rgb: 80, 88, 98;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a616b;
  --bs-btn-active-border-color: #464e59;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #313a46;
  --bs-btn-disabled-border-color: #313a46;
}

.btn-outline-primary {
  --bs-btn-color: #212245;
  --bs-btn-border-color: #212245;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212245;
  --bs-btn-hover-border-color: #212245;
  --bs-btn-focus-shadow-rgb: 33, 34, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212245;
  --bs-btn-active-border-color: #212245;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #212245;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212245;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #0acf97;
  --bs-btn-border-color: #0acf97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0acf97;
  --bs-btn-hover-border-color: #0acf97;
  --bs-btn-focus-shadow-rgb: 10, 207, 151;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0acf97;
  --bs-btn-active-border-color: #0acf97;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #0acf97;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0acf97;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #39afd1;
  --bs-btn-border-color: #39afd1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #39afd1;
  --bs-btn-hover-border-color: #39afd1;
  --bs-btn-focus-shadow-rgb: 57, 175, 209;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #39afd1;
  --bs-btn-active-border-color: #39afd1;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #39afd1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #39afd1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffbc00;
  --bs-btn-border-color: #ffbc00;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #ffbc00;
  --bs-btn-hover-border-color: #ffbc00;
  --bs-btn-focus-shadow-rgb: 255, 188, 0;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #ffbc00;
  --bs-btn-active-border-color: #ffbc00;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #ffbc00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffbc00;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fa5c7c;
  --bs-btn-border-color: #fa5c7c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fa5c7c;
  --bs-btn-hover-border-color: #fa5c7c;
  --bs-btn-focus-shadow-rgb: 250, 92, 124;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fa5c7c;
  --bs-btn-active-border-color: #fa5c7c;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fa5c7c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fa5c7c;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #eef2f7;
  --bs-btn-border-color: #eef2f7;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #eef2f7;
  --bs-btn-hover-border-color: #eef2f7;
  --bs-btn-focus-shadow-rgb: 238, 242, 247;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #eef2f7;
  --bs-btn-active-border-color: #eef2f7;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #eef2f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eef2f7;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #313a46;
  --bs-btn-border-color: #313a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #313a46;
  --bs-btn-hover-border-color: #313a46;
  --bs-btn-focus-shadow-rgb: 49, 58, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #313a46;
  --bs-btn-active-border-color: #313a46;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #313a46;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--ct-btn-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--ct-btn-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--ct-btn-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--ct-btn-link-disabled-color);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 66, 67, 97;
  text-decoration: none;
}

.btn-link:focus {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.2501rem + .0012vw);
  --bs-btn-border-radius: .15rem;
}

@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.251rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .28rem;
  --bs-btn-padding-x: .8rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .15rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .2125em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .25rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: .9rem;
  --bs-dropdown-color: var(--ct-dropdown-color);
  --bs-dropdown-bg: var(--ct-dropdown-bg);
  --bs-dropdown-border-color: var(--ct-dropdown-border-color);
  --bs-dropdown-border-radius: .25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.25rem - 1px);
  --bs-dropdown-divider-bg: var(--ct-dropdown-divider-bg);
  --bs-dropdown-divider-margin-y: .75rem;
  --bs-dropdown-box-shadow: var(--ct-dropdown-box-shadow);
  --bs-dropdown-link-color: var(--ct-dropdown-link-color);
  --bs-dropdown-link-hover-color: var(--ct-dropdown-link-hover-color);
  --bs-dropdown-link-hover-bg: var(--ct-dropdown-link-hover-bg);
  --bs-dropdown-link-active-color: var(--ct-dropdown-link-active-color);
  --bs-dropdown-link-active-bg: var(--ct-dropdown-link-active-bg);
  --bs-dropdown-link-disabled-color: var(--ct-dropdown-link-disabled-color);
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: .375rem;
  --bs-dropdown-header-color: inherit;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: 1000;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .2125em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .2125em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  margin-left: .2125em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .2125em;
  content: "";
  border-top: .25em solid #0000;
  border-bottom: .25em solid #0000;
  border-right: .25em solid;
  margin-right: .2125em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: var(--ct-dropdown-dark-color);
  --bs-dropdown-bg: var(--ct-dropdown-dark-bg);
  --bs-dropdown-border-color: var(--ct-dropdown-dark-border-color);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: var(--ct-dropdown-dark-link-color);
  --bs-dropdown-link-hover-color: var(--ct-dropdown-dark-link-hover-color);
  --bs-dropdown-divider-bg: var(--ct-dropdown-dark-divider-bg);
  --bs-dropdown-link-hover-bg: var(--ct-dropdown-dark-link-hover-bg);
  --bs-dropdown-link-active-color: var(--ct-dropdown-dark-link-active-color);
  --bs-dropdown-link-active-bg: var(--ct-dropdown-dark-link-active-bg);
  --bs-dropdown-link-disabled-color: var(--ct-dropdown-dark-link-disabled-color);
  --bs-dropdown-header-color: var(--ct-dropdown-dark-header-color);
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .15rem;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .675rem;
  padding-right: .675rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .6rem;
  padding-right: .6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--ct-nav-link-disabled-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: var(--ct-nav-tabs-border-color);
  --bs-nav-tabs-border-radius: .25rem;
  --bs-nav-tabs-link-hover-border-color: var(--ct-nav-tabs-link-hover-border-color);
  --bs-nav-tabs-link-active-color: var(--ct-nav-tabs-link-active-color);
  --bs-nav-tabs-link-active-bg: var(--ct-nav-tabs-link-active-bg);
  --bs-nav-tabs-link-active-border-color: var(--ct-nav-tabs-link-active-border-color);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .25rem;
  --bs-nav-pills-link-active-color: var(--ct-component-active-color);
  --bs-nav-pills-link-active-bg: var(--ct-component-active-bg);
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .75rem;
  --bs-navbar-color: var(--ct-navbar-light-color);
  --bs-navbar-hover-color: var(--ct-navbar-light-hover-color);
  --bs-navbar-disabled-color: var(--ct-navbar-light-disabled-color);
  --bs-navbar-active-color: var(--ct-navbar-light-active-color);
  --bs-navbar-brand-padding-y: .23675rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.251rem;
  --bs-navbar-brand-color: var(--ct-navbar-light-active-color);
  --bs-navbar-brand-hover-color: var(--ct-navbar-light-active-color);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.251rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='var%28--ct-navbar-light-color%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .15rem;
  --bs-navbar-toggler-focus-width: .2rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: var(--ct-navbar-dark-color);
  --bs-navbar-hover-color: var(--ct-navbar-dark-hover-color);
  --bs-navbar-disabled-color: var(--ct-navbar-dark-disabled-color);
  --bs-navbar-active-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-brand-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-brand-hover-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-toggler-border-color: var(--ct-navbar-dark-toggler-border-color);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='var%28--ct-navbar-dark-color%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: .75rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--ct-card-border-color);
  --bs-card-border-radius: .25rem;
  --bs-card-box-shadow: var(--ct-card-box-shadow);
  --bs-card-inner-border-radius: calc(.25rem - 1px);
  --bs-card-cap-padding-y: .75rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: var(--ct-card-cap-bg);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--ct-card-bg);
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 12px;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--ct-accordion-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .25rem;
  --bs-accordion-inner-border-radius: calc(.25rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--ct-accordion-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-accordion-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-accordion-button-active-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: var(--ct-accordion-button-focus-border-color);
  --bs-accordion-btn-focus-box-shadow: var(--ct-accordion-button-focus-box-shadow);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--ct-accordion-button-active-color);
  --bs-accordion-active-bg: var(--ct-accordion-button-active-bg);
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: .9rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 1.5rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--ct-breadcrumb-divider-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--ct-breadcrumb-active-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "󰅂");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--ct-pagination-color);
  --bs-pagination-bg: var(--ct-pagination-bg);
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: var(--ct-pagination-border-color);
  --bs-pagination-border-radius: .25rem;
  --bs-pagination-hover-color: var(--ct-pagination-hover-color);
  --bs-pagination-hover-bg: var(--ct-pagination-hover-bg);
  --bs-pagination-hover-border-color: var(--ct-pagination-hover-border-color);
  --bs-pagination-focus-color: var(--ct-pagination-focus-color);
  --bs-pagination-focus-bg: var(--ct-pagination-focus-bg);
  --bs-pagination-focus-box-shadow: var(--ct-pagination-focus-box-shadow);
  --bs-pagination-active-color: var(--ct-component-active-color);
  --bs-pagination-active-bg: var(--ct-component-active-bg);
  --bs-pagination-active-border-color: var(--ct-component-active-bg);
  --bs-pagination-disabled-color: var(--ct-pagination-disabled-color);
  --bs-pagination-disabled-bg: var(--ct-pagination-disabled-bg);
  --bs-pagination-disabled-border-color: var(--ct-pagination-disabled-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: calc(1.2501rem + .0012vw);
  --bs-pagination-border-radius: .3rem;
}

@media (min-width: 1200px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.251rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .2rem;
}

.badge {
  --bs-badge-padding-x: .4em;
  --bs-badge-padding-y: .25em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: var(--ct-badge-color);
  --bs-badge-border-radius: .25rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: .75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .25rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: .9375rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #141429;
  --bs-alert-bg: #d3d3da;
  --bs-alert-border-color: #bcbdc7;
}

.alert-primary .alert-link {
  color: #101021;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #067c5b;
  --bs-alert-bg: #cef5ea;
  --bs-alert-border-color: #b6f1e0;
}

.alert-success .alert-link {
  color: #056349;
}

.alert-info {
  --bs-alert-color: #22697d;
  --bs-alert-bg: #d7eff6;
  --bs-alert-border-color: #c4e7f1;
}

.alert-info .alert-link {
  color: #1b5464;
}

.alert-warning {
  --bs-alert-color: #997100;
  --bs-alert-bg: #fff2cc;
  --bs-alert-border-color: #ffebb3;
}

.alert-warning .alert-link {
  color: #7a5a00;
}

.alert-danger {
  --bs-alert-color: #96374a;
  --bs-alert-bg: #fedee5;
  --bs-alert-border-color: #feced8;
}

.alert-danger .alert-link {
  color: #782c3b;
}

.alert-light {
  --bs-alert-color: #8f9194;
  --bs-alert-bg: #fcfcfd;
  --bs-alert-border-color: #fafbfd;
}

.alert-light .alert-link {
  color: #727476;
}

.alert-dark {
  --bs-alert-color: #1d232a;
  --bs-alert-bg: #d6d8da;
  --bs-alert-border-color: #c1c4c8;
}

.alert-dark .alert-link {
  color: #171c22;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .675rem;
  --bs-progress-bg: var(--ct-progress-bg);
  --bs-progress-border-radius: .25rem;
  --bs-progress-box-shadow: var(--ct-progress-box-shadow);
  --bs-progress-bar-color: var(--ct-progress-bar-color);
  --bs-progress-bar-bg: var(--ct-progress-bar-bg);
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--ct-list-group-color);
  --bs-list-group-bg: var(--ct-list-group-bg);
  --bs-list-group-border-color: var(--ct-list-group-border-color);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .25rem;
  --bs-list-group-item-padding-x: 1.2rem;
  --bs-list-group-item-padding-y: .75rem;
  --bs-list-group-action-color: var(--ct-list-group-action-color);
  --bs-list-group-action-hover-color: var(--ct-list-group-action-color);
  --bs-list-group-action-hover-bg: var(--ct-list-group-hover-bg);
  --bs-list-group-action-active-color: var(--ct-list-group-action-active-color);
  --bs-list-group-action-active-bg: var(--ct-list-group-action-active-bg);
  --bs-list-group-disabled-color: var(--ct-list-group-disabled-color);
  --bs-list-group-disabled-bg: var(--ct-list-group-disabled-bg);
  --bs-list-group-active-color: var(--ct-component-active-color);
  --bs-list-group-active-bg: var(--ct-component-active-bg);
  --bs-list-group-active-border-color: var(--ct-component-active-bg);
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #141429;
  background-color: #bcbdc7;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #141429;
  background-color: #a9aab3;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #141429;
  border-color: #141429;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #d3d6d8;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #bec1c2;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #067c5b;
  background-color: #b6f1e0;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #067c5b;
  background-color: #a4d9ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #067c5b;
  border-color: #067c5b;
}

.list-group-item-info {
  color: #22697d;
  background-color: #c4e7f1;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #22697d;
  background-color: #b0d0d9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #22697d;
  border-color: #22697d;
}

.list-group-item-warning {
  color: #997100;
  background-color: #ffebb3;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997100;
  background-color: #e6d4a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997100;
  border-color: #997100;
}

.list-group-item-danger {
  color: #96374a;
  background-color: #feced8;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #96374a;
  background-color: #e5b9c2;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #96374a;
  border-color: #96374a;
}

.list-group-item-light {
  color: #8f9194;
  background-color: #fafbfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8f9194;
  background-color: #e1e2e4;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8f9194;
  border-color: #8f9194;
}

.list-group-item-dark {
  color: #1d232a;
  background-color: #c1c4c8;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1d232a;
  background-color: #aeb0b4;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1d232a;
  border-color: #1d232a;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--ct-btn-close-color);
  opacity: 1;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238b96a0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .25rem;
  padding: .25em;
}

.btn-close:hover {
  color: var(--ct-btn-close-color);
  opacity: .8;
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 12px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: inherit;
  --bs-toast-bg: var(--ct-toast-background-color);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--ct-toast-border-color);
  --bs-toast-border-radius: .25rem;
  --bs-toast-box-shadow: var(--ct-box-shadow);
  --bs-toast-header-color: inherit;
  --bs-toast-header-bg: var(--ct-toast-header-background-color);
  --bs-toast-header-border-color: var(--ct-toast-header-border-color);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--ct-modal-content-bg);
  --bs-modal-border-color: transparent;
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .2rem;
  --bs-modal-box-shadow: var(--ct-modal-content-box-shadow-xs);
  --bs-modal-inner-border-radius: calc(.2rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--ct-modal-header-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--ct-modal-footer-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: var(--ct-modal-backdrop-bg);
  --bs-backdrop-opacity: .7;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--ct-modal-content-box-shadow-sm-up);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .8rem;
  --bs-tooltip-padding-y: .4rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: .8125rem;
  --bs-tooltip-color: var(--ct-tooltip-color);
  --bs-tooltip-bg: var(--ct-tooltip-bg);
  --bs-tooltip-border-radius: .2rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--ct-popover-bg);
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--ct-popover-border-color);
  --bs-popover-border-radius: .3rem;
  --bs-popover-inner-border-radius: calc(.3rem - 1px);
  --bs-popover-box-shadow: var(--ct-box-shadow);
  --bs-popover-header-padding-x: .8rem;
  --bs-popover-header-padding-y: .7rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--ct-popover-header-color);
  --bs-popover-header-bg: var(--ct-popover-header-bg);
  --bs-popover-body-padding-x: .75rem;
  --bs-popover-body-padding-y: .5rem;
  --bs-popover-body-color: var(--ct-popover-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: var(--ct-carousel-control-color);
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: var(--ct-carousel-control-color);
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--ct-carousel-indicator-active-bg);
  opacity: .5;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: var(--ct-carousel-caption-color);
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--ct-carousel-dark-indicator-active-bg);
}

.carousel-dark .carousel-caption {
  color: var(--ct-carousel-dark-caption-color);
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: var(--ct-modal-content-bg);
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: transparent;
  --bs-offcanvas-box-shadow: var(--ct-modal-content-box-shadow-xs);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: 1045;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: 1045;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: 1045;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: 1045;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: 1045;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: 1045;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--ct-modal-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .7;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: placeholder-wave 2s linear infinite;
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(33, 34, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(10, 207, 151, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(57, 175, 209, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #313a46 !important;
  background-color: RGBA(255, 188, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(250, 92, 124, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #313a46 !important;
  background-color: RGBA(238, 242, 247, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(49, 58, 70, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #212245 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #1c1d3b !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #5c636a !important;
}

.link-success {
  color: #0acf97 !important;
}

.link-success:hover, .link-success:focus {
  color: #09b080 !important;
}

.link-info {
  color: #39afd1 !important;
}

.link-info:hover, .link-info:focus {
  color: #3095b2 !important;
}

.link-warning {
  color: #ffbc00 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffc626 !important;
}

.link-danger {
  color: #fa5c7c !important;
}

.link-danger:hover, .link-danger:focus {
  color: #d54e69 !important;
}

.link-light {
  color: #eef2f7 !important;
}

.link-light:hover, .link-light:focus {
  color: #f1f4f8 !important;
}

.link-dark {
  color: #313a46 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #2a313c !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--ct-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--ct-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--ct-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-0 {
  --bs-border-width: 0;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .375rem !important;
}

.m-2 {
  margin: .75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .375rem !important;
  margin-right: .375rem !important;
}

.mx-2 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-4 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.mx-5 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .375rem !important;
  margin-bottom: .375rem !important;
}

.my-2 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .375rem !important;
}

.mt-2 {
  margin-top: .75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .375rem !important;
}

.me-2 {
  margin-right: .75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .375rem !important;
}

.mb-2 {
  margin-bottom: .75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .375rem !important;
}

.ms-2 {
  margin-left: .75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.375rem !important;
}

.m-n2 {
  margin: -.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-left: -.375rem !important;
  margin-right: -.375rem !important;
}

.mx-n2 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.mx-n3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n4 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.mx-n5 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.my-n1 {
  margin-top: -.375rem !important;
  margin-bottom: -.375rem !important;
}

.my-n2 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -.375rem !important;
}

.mt-n2 {
  margin-top: -.75rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mt-n5 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -.375rem !important;
}

.me-n2 {
  margin-right: -.75rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2.25rem !important;
}

.me-n5 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -.375rem !important;
}

.mb-n2 {
  margin-bottom: -.75rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.mb-n5 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -.375rem !important;
}

.ms-n2 {
  margin-left: -.75rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2.25rem !important;
}

.ms-n5 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .375rem !important;
}

.p-2 {
  padding: .75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .375rem !important;
  padding-right: .375rem !important;
}

.px-2 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-5 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.py-2 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .375rem !important;
}

.pt-2 {
  padding-top: .75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .375rem !important;
}

.pe-2 {
  padding-right: .75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .375rem !important;
}

.pb-2 {
  padding-bottom: .75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .375rem !important;
}

.ps-2 {
  padding-left: .75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .375rem !important;
}

.gap-2 {
  gap: .75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + .75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: .9375rem !important;
}

.fs-6 {
  font-size: .75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--ct-text-muted) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .375rem !important;
  }

  .m-sm-2 {
    margin: .75rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.25rem !important;
  }

  .m-sm-5 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-sm-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-sm-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-sm-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-sm-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .375rem !important;
  }

  .mt-sm-2 {
    margin-top: .75rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .375rem !important;
  }

  .me-sm-2 {
    margin-right: .75rem !important;
  }

  .me-sm-3 {
    margin-right: 1.5rem !important;
  }

  .me-sm-4 {
    margin-right: 2.25rem !important;
  }

  .me-sm-5 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .375rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .75rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .375rem !important;
  }

  .ms-sm-2 {
    margin-left: .75rem !important;
  }

  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.375rem !important;
  }

  .m-sm-n2 {
    margin: -.75rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2.25rem !important;
  }

  .m-sm-n5 {
    margin: -4.5rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-sm-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-sm-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.375rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.75rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n5 {
    margin-top: -4.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -.375rem !important;
  }

  .me-sm-n2 {
    margin-right: -.75rem !important;
  }

  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n4 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.375rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.75rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n5 {
    margin-left: -4.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .375rem !important;
  }

  .p-sm-2 {
    padding: .75rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.25rem !important;
  }

  .p-sm-5 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-sm-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-sm-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-sm-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-sm-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .375rem !important;
  }

  .pt-sm-2 {
    padding-top: .75rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .375rem !important;
  }

  .pe-sm-2 {
    padding-right: .75rem !important;
  }

  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .375rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .75rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .375rem !important;
  }

  .ps-sm-2 {
    padding-left: .75rem !important;
  }

  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .375rem !important;
  }

  .gap-sm-2 {
    gap: .75rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2.25rem !important;
  }

  .gap-sm-5 {
    gap: 4.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .375rem !important;
  }

  .m-md-2 {
    margin: .75rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.25rem !important;
  }

  .m-md-5 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-md-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-md-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-md-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-md-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .375rem !important;
  }

  .mt-md-2 {
    margin-top: .75rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2.25rem !important;
  }

  .mt-md-5 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .375rem !important;
  }

  .me-md-2 {
    margin-right: .75rem !important;
  }

  .me-md-3 {
    margin-right: 1.5rem !important;
  }

  .me-md-4 {
    margin-right: 2.25rem !important;
  }

  .me-md-5 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .375rem !important;
  }

  .mb-md-2 {
    margin-bottom: .75rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .375rem !important;
  }

  .ms-md-2 {
    margin-left: .75rem !important;
  }

  .ms-md-3 {
    margin-left: 1.5rem !important;
  }

  .ms-md-4 {
    margin-left: 2.25rem !important;
  }

  .ms-md-5 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.375rem !important;
  }

  .m-md-n2 {
    margin: -.75rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2.25rem !important;
  }

  .m-md-n5 {
    margin: -4.5rem !important;
  }

  .mx-md-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-md-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-md-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-md-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-md-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-md-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -.375rem !important;
  }

  .mt-md-n2 {
    margin-top: -.75rem !important;
  }

  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n5 {
    margin-top: -4.5rem !important;
  }

  .me-md-n1 {
    margin-right: -.375rem !important;
  }

  .me-md-n2 {
    margin-right: -.75rem !important;
  }

  .me-md-n3 {
    margin-right: -1.5rem !important;
  }

  .me-md-n4 {
    margin-right: -2.25rem !important;
  }

  .me-md-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -.375rem !important;
  }

  .ms-md-n2 {
    margin-left: -.75rem !important;
  }

  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n5 {
    margin-left: -4.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .375rem !important;
  }

  .p-md-2 {
    padding: .75rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.25rem !important;
  }

  .p-md-5 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-md-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-md-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-md-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-md-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .375rem !important;
  }

  .pt-md-2 {
    padding-top: .75rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2.25rem !important;
  }

  .pt-md-5 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .375rem !important;
  }

  .pe-md-2 {
    padding-right: .75rem !important;
  }

  .pe-md-3 {
    padding-right: 1.5rem !important;
  }

  .pe-md-4 {
    padding-right: 2.25rem !important;
  }

  .pe-md-5 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .375rem !important;
  }

  .pb-md-2 {
    padding-bottom: .75rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .375rem !important;
  }

  .ps-md-2 {
    padding-left: .75rem !important;
  }

  .ps-md-3 {
    padding-left: 1.5rem !important;
  }

  .ps-md-4 {
    padding-left: 2.25rem !important;
  }

  .ps-md-5 {
    padding-left: 4.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .375rem !important;
  }

  .gap-md-2 {
    gap: .75rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2.25rem !important;
  }

  .gap-md-5 {
    gap: 4.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .375rem !important;
  }

  .m-lg-2 {
    margin: .75rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.25rem !important;
  }

  .m-lg-5 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-lg-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-lg-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-lg-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-lg-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .375rem !important;
  }

  .mt-lg-2 {
    margin-top: .75rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .375rem !important;
  }

  .me-lg-2 {
    margin-right: .75rem !important;
  }

  .me-lg-3 {
    margin-right: 1.5rem !important;
  }

  .me-lg-4 {
    margin-right: 2.25rem !important;
  }

  .me-lg-5 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .375rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .75rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .375rem !important;
  }

  .ms-lg-2 {
    margin-left: .75rem !important;
  }

  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.375rem !important;
  }

  .m-lg-n2 {
    margin: -.75rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2.25rem !important;
  }

  .m-lg-n5 {
    margin: -4.5rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-lg-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-lg-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.375rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.75rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n5 {
    margin-top: -4.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -.375rem !important;
  }

  .me-lg-n2 {
    margin-right: -.75rem !important;
  }

  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n4 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.375rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.75rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n5 {
    margin-left: -4.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .375rem !important;
  }

  .p-lg-2 {
    padding: .75rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.25rem !important;
  }

  .p-lg-5 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-lg-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-lg-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-lg-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-lg-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .375rem !important;
  }

  .pt-lg-2 {
    padding-top: .75rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .375rem !important;
  }

  .pe-lg-2 {
    padding-right: .75rem !important;
  }

  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .375rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .75rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .375rem !important;
  }

  .ps-lg-2 {
    padding-left: .75rem !important;
  }

  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .375rem !important;
  }

  .gap-lg-2 {
    gap: .75rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2.25rem !important;
  }

  .gap-lg-5 {
    gap: 4.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .375rem !important;
  }

  .m-xl-2 {
    margin: .75rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.25rem !important;
  }

  .m-xl-5 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-xl-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xl-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-xl-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .375rem !important;
  }

  .mt-xl-2 {
    margin-top: .75rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .375rem !important;
  }

  .me-xl-2 {
    margin-right: .75rem !important;
  }

  .me-xl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .375rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .75rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .375rem !important;
  }

  .ms-xl-2 {
    margin-left: .75rem !important;
  }

  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.375rem !important;
  }

  .m-xl-n2 {
    margin: -.75rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2.25rem !important;
  }

  .m-xl-n5 {
    margin: -4.5rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xl-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-xl-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.375rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.75rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n5 {
    margin-top: -4.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -.375rem !important;
  }

  .me-xl-n2 {
    margin-right: -.75rem !important;
  }

  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n4 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.375rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.75rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n5 {
    margin-left: -4.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .375rem !important;
  }

  .p-xl-2 {
    padding: .75rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.25rem !important;
  }

  .p-xl-5 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-xl-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xl-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-xl-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .375rem !important;
  }

  .pt-xl-2 {
    padding-top: .75rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .375rem !important;
  }

  .pe-xl-2 {
    padding-right: .75rem !important;
  }

  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .375rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .75rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .375rem !important;
  }

  .ps-xl-2 {
    padding-left: .75rem !important;
  }

  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .375rem !important;
  }

  .gap-xl-2 {
    gap: .75rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2.25rem !important;
  }

  .gap-xl-5 {
    gap: 4.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .375rem !important;
  }

  .m-xxl-2 {
    margin: .75rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2.25rem !important;
  }

  .m-xxl-5 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-xxl-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xxl-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-xxl-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .375rem !important;
  }

  .mt-xxl-2 {
    margin-top: .75rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .375rem !important;
  }

  .me-xxl-2 {
    margin-right: .75rem !important;
  }

  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .375rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .75rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .375rem !important;
  }

  .ms-xxl-2 {
    margin-left: .75rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.375rem !important;
  }

  .m-xxl-n2 {
    margin: -.75rem !important;
  }

  .m-xxl-n3 {
    margin: -1.5rem !important;
  }

  .m-xxl-n4 {
    margin: -2.25rem !important;
  }

  .m-xxl-n5 {
    margin: -4.5rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.375rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.75rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -4.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.375rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.75rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n4 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.375rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.75rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -4.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .375rem !important;
  }

  .p-xxl-2 {
    padding: .75rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2.25rem !important;
  }

  .p-xxl-5 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-xxl-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xxl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xxl-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-xxl-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .375rem !important;
  }

  .pt-xxl-2 {
    padding-top: .75rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .375rem !important;
  }

  .pe-xxl-2 {
    padding-right: .75rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .375rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .75rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .375rem !important;
  }

  .ps-xxl-2 {
    padding-left: .75rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .375rem !important;
  }

  .gap-xxl-2 {
    gap: .75rem !important;
  }

  .gap-xxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxl-4 {
    gap: 2.25rem !important;
  }

  .gap-xxl-5 {
    gap: 4.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

html {
  min-height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.logo {
  width: 260px;
  line-height: 70px;
  display: block;
  position: fixed;
  top: 0;
}

.logo span.logo-lg {
  display: block;
}

.logo span.logo-sm {
  display: none;
}

.logo.logo-light {
  display: block;
}

.logo.logo-dark {
  display: none;
}

.wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.content-page {
  min-height: 100vh;
  margin-left: 260px;
  padding: 70px 12px 65px;
  overflow: hidden;
}

.leftside-menu {
  width: 260px;
  z-index: 10;
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  padding-top: 70px;
  position: fixed;
  top: 0;
  bottom: 0;
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}

.side-nav ul {
  list-style-type: none;
}

.side-nav .side-nav-link {
  color: var(--ct-menu-item);
  padding: 10px 30px;
  font-size: .9375rem;
  list-style: none;
  transition: all .4s;
  display: block;
  position: relative;
}

.side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover);
  text-decoration: none;
}

.side-nav .side-nav-link span {
  vertical-align: middle;
}

.side-nav .side-nav-link i {
  vertical-align: middle;
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  line-height: 1.0625rem;
  display: inline-block;
}

.side-nav .menu-arrow {
  text-rendering: auto;
  font-family: Material Design Icons;
  font-size: 1.1rem;
  line-height: 1.5rem;
  transition: transform .15s;
  display: inline-block;
  position: absolute;
  right: 30px;
  transform: translate(0);
}

.side-nav .menu-arrow:before {
  content: "󰅂";
}

.side-nav .badge {
  margin-top: 3px;
}

.side-nav .side-nav-item > a[aria-expanded="true"] > span.menu-arrow, .side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
  transform: rotate(90deg);
}

.side-nav .side-nav-title {
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  text-transform: uppercase;
  color: var(--ct-menu-item);
  padding: 12px 30px;
  font-size: .6875rem;
  font-weight: 700;
}

.side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

[dir="ltr"] .side-nav .side-nav-item .menu-arrow:before {
  content: "󰅁" !important;
}

.side-nav-second-level, .side-nav-third-level, .side-nav-forth-level {
  padding-left: 0;
}

.side-nav-second-level li a, .side-nav-second-level li .side-nav-link, .side-nav-third-level li a, .side-nav-third-level li .side-nav-link, .side-nav-forth-level li a, .side-nav-forth-level li .side-nav-link {
  color: var(--ct-menu-item);
  padding: 8px 30px 8px 65px;
  font-size: .89rem;
  transition: all .4s;
  display: block;
  position: relative;
}

.side-nav-second-level li a:focus, .side-nav-second-level li a:hover, .side-nav-second-level li .side-nav-link:focus, .side-nav-second-level li .side-nav-link:hover, .side-nav-third-level li a:focus, .side-nav-third-level li a:hover, .side-nav-third-level li .side-nav-link:focus, .side-nav-third-level li .side-nav-link:hover, .side-nav-forth-level li a:focus, .side-nav-forth-level li a:hover, .side-nav-forth-level li .side-nav-link:focus, .side-nav-forth-level li .side-nav-link:hover {
  color: var(--ct-menu-item-hover);
}

.side-nav-second-level li a .menu-arrow, .side-nav-second-level li .side-nav-link .menu-arrow, .side-nav-third-level li a .menu-arrow, .side-nav-third-level li .side-nav-link .menu-arrow, .side-nav-forth-level li a .menu-arrow, .side-nav-forth-level li .side-nav-link .menu-arrow {
  line-height: 1.3rem;
}

.side-nav-second-level li.active > a, .side-nav-third-level li.active > a, .side-nav-forth-level li.active > a {
  color: var(--ct-menu-item-active);
}

.side-nav-third-level li a, .side-nav-third-level li .side-nav-link {
  padding: 8px 30px 8px 80px;
}

.side-nav-forth-level li a, .side-nav-forth-level li .side-nav-link {
  padding: 8px 30px 8px 100px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) {
  min-height: 1600px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu {
  width: 70px;
  z-index: 5;
  padding-top: 70px;
  position: absolute;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-mask, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-content-wrapper {
  overflow: visible !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-scrollbar {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-offset {
  bottom: 0 !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .logo {
  width: 70px;
  z-index: 1;
  background: var(--ct-bg-leftbar-gradient);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .help-box {
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .content-page {
  margin-left: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .navbar-custom, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .footer {
  left: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-title, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .badge, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .menu-arrow {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing {
  display: none;
  height: inherit !important;
  transition: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-second-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-third-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-forth-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-second-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-third-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-forth-level {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item {
  white-space: nowrap;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link {
  min-height: 54px;
  padding: 15px 20px;
  transition: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link i {
  margin-left: 6px;
  margin-right: 20px;
  font-size: 1.125rem;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link span {
  padding-left: 10px;
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link {
  width: 200px;
  color: #fff;
  background: var(--ct-bg-leftbar-gradient);
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link span {
  display: inline;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul {
  width: 190px;
  z-index: 9999;
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  padding: 5px 0;
  position: absolute;
  left: 70px;
  height: auto !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a {
  width: 190px;
  padding: 8px 20px;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing {
  transition: none !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul {
  width: 190px;
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  position: absolute;
  left: 70px;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a {
  box-shadow: none;
  width: 190px;
  z-index: 6;
  padding: 8px 20px;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a:hover, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse {
  height: auto !important;
  transition: none !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul {
  width: 190px;
  display: block;
  position: absolute;
  top: 0;
  left: 190px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-lg {
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-sm {
  color: #212245;
  line-height: 70px;
  display: block;
}

@media (max-width: 767.98px) {
  body {
    overflow-x: hidden;
  }

  .leftside-menu {
    box-shadow: var(--ct-box-shadow);
    display: none;
    z-index: 10 !important;
  }

  .sidebar-enable .leftside-menu {
    display: block;
  }

  .navbar-nav.navbar-right {
    float: right;
  }

  .content-page {
    padding: 65px 10px;
    margin-left: 0 !important;
  }

  body[data-leftbar-compact-mode="condensed"] .leftside-menu {
    margin-left: 0;
  }

  .logo span.logo-lg {
    display: block;
  }

  .logo span.logo-sm {
    display: none;
  }
}

.help-box {
  background-color: var(--ct-help-box-light-bg);
  border-radius: 5px;
  margin: 65px 25px 25px;
  padding: 20px;
  position: relative;
}

.help-box .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

body[data-leftbar-theme="light"] .help-box {
  background-color: #212245;
}

body[data-leftbar-theme="light"] .logo.logo-light {
  display: none;
}

body[data-leftbar-theme="light"] .logo.logo-dark {
  display: block;
}

body[data-layout="topnav"] .content-page {
  padding: 0 0 60px;
  margin-left: 0 !important;
}

body[data-layout-mode="boxed"] {
  background-color: var(--ct-boxed-layout-bg);
}

body[data-layout-mode="boxed"] .wrapper {
  max-width: 1300px;
  box-shadow: var(--ct-box-shadow);
  background-color: #fafbfe;
  margin: 0 auto;
}

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .logo {
  margin-top: -70px;
  position: relative;
}

@media (min-width: 1200px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    padding-bottom: 0;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .wrapper {
    display: flex;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .leftside-menu {
    min-width: 260px;
    max-width: 260px;
    padding-top: 0;
    position: relative;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .logo {
    margin-top: 0;
    position: relative;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .content-page {
    width: 100%;
    margin-left: 0;
    padding-bottom: 60px;
  }
}

body[data-layout="detached"] {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  body[data-layout="detached"] .container-fluid, body[data-layout="detached"] .container-sm, body[data-layout="detached"] .container-md, body[data-layout="detached"] .container-lg, body[data-layout="detached"] .container-xl, body[data-layout="detached"] .container-xxl {
    max-width: 95%;
  }
}

body[data-layout="detached"][data-layout-mode="boxed"] .wrapper {
  max-width: 100%;
}

body[data-layout="detached"] .wrapper {
  overflow: inherit;
  display: flex;
}

body[data-layout="detached"] .content-page {
  width: 100%;
  margin-left: 0;
  margin-right: -15px;
  padding: 0 15px 60px 30px;
  position: relative;
  overflow: hidden;
}

body[data-layout="detached"] .leftside-menu {
  min-width: 260px;
  max-width: 260px;
  box-shadow: var(--ct-box-shadow);
  margin-top: 30px;
  position: relative;
  background: var(--ct-bg-detached-leftbar) !important;
  z-index: 1001 !important;
  padding-top: 0 !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link {
  color: var(--ct-menu-item) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a {
  color: var(--ct-menu-item);
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li.mm-active > a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li.mm-active > a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li.mm-active > a {
  color: var(--ct-menu-item-active);
}

body[data-layout="detached"] .leftside-menu .side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-title {
  color: var(--ct-menu-item);
}

body[data-layout="detached"] .leftbar-user {
  text-align: center;
  padding: 30px 20px;
}

body[data-layout="detached"] .leftbar-user .leftbar-user-name {
  color: #313a46;
  margin-top: 8px;
  margin-left: 12px;
  font-weight: 700;
  display: block;
}

@media (max-width: 767.98px) {
  body[data-layout="detached"].sidebar-enable .leftside-menu {
    margin-top: 70px;
    position: fixed;
    left: 0;
    overflow-y: auto;
  }

  body[data-layout="detached"] .wrapper {
    max-width: 100%;
  }

  body[data-layout="detached"] .content-page {
    padding: 0 10px 60px;
    margin-left: 0 !important;
  }

  body[data-layout="detached"] .lang-switch {
    display: none;
  }

  body[data-layout="detached"][data-leftbar-compact-mode="condensed"].sidebar-enable .leftside-menu {
    margin-top: 0;
  }
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu {
  max-width: 70px;
  min-width: 70px;
  position: relative;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftbar-user {
  display: none;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .content-page {
  margin-left: 0;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .footer {
  left: 0;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #212245;
  transition: none;
  color: #fff !important;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul {
  box-shadow: var(--ct-box-shadow);
  background: var(--ct-bg-leftbar) !important;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul a:hover {
  color: var(--ct-menu-item-hover) !important;
}

@media (min-width: 992px) {
  body[data-layout="detached"][data-leftbar-compact-mode="scrollable"] .wrapper {
    padding-top: 70px;
  }
}

.button-menu-mobile .lines {
  width: 18px;
  height: 16px;
  margin-top: 26px;
  margin-left: 10px;
  transition: all .5s;
  display: block;
  position: relative;
}

.button-menu-mobile span {
  height: 2px;
  width: 100%;
  background-color: #fffc;
  margin-bottom: 5px;
  transition: transform .5s;
  display: block;
}

.button-menu-mobile span:nth-of-type(2) {
  width: 24px;
}

.button-menu-mobile.disable-btn {
  display: none;
}

body[data-leftbar-theme="light"] .leftside-menu {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="light"] .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-link {
  color: var(--ct-menu-item);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"] .side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-title, body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a {
  color: var(--ct-menu-item);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li.active > a, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li.active > a, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li.active > a {
  color: var(--ct-menu-item-active);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #212245;
  transition: none;
  color: #fff !important;
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: var(--ct-bg-leftbar);
  box-shadow: var(--ct-box-shadow);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul a:hover, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="dark"] .leftside-menu {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="dark"] .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: var(--ct-dark-menu-item);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:active {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  color: var(--ct-dark-menu-item-active) !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-title, body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a {
  color: var(--ct-dark-menu-item);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li.active > a, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li.active > a, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li.active > a {
  color: var(--ct-dark-menu-item-active);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #212245;
  transition: none;
  color: #fff !important;
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: var(--ct-bg-leftbar);
  box-shadow: var(--ct-box-shadow);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: var(--ct-bg-leftbar);
}

body[data-layout="full"] .navbar-custom .button-menu-mobile.disable-btn {
  display: block;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu {
  padding-top: 0;
  width: 0 !important;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .side-nav {
  opacity: .2;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo {
  width: 70px;
  background: var(--ct-bg-leftbar-gradient) !important;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo span.logo-lg {
  display: none;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo span.logo-sm {
  display: block;
}

body[data-layout="full"].hide-menu .content-page {
  margin-left: 0 !important;
}

body[data-layout="full"].hide-menu .content-page .navbar-custom {
  left: 70px;
}

body[data-layout="full"].hide-menu .content-page .footer {
  left: 0 !important;
}

body[data-layout="full"].hide-menu[data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu {
  padding-top: 70px;
}

body[data-layout="full"].hide-menu[data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu .side-nav {
  visibility: hidden;
  transform: translateX(-50px);
}

body[data-layout="full"][data-leftbar-compact-mode="scrollable"].hide-menu .wrapper .leftside-menu {
  max-width: 0;
  min-width: 0;
}

body[data-layout="full"][data-leftbar-compact-mode="scrollable"] .wrapper .leftside-menu {
  padding-top: 0 !important;
}

body[data-layout="full"][data-leftbar-theme="dark"].hide-menu .wrapper .leftside-menu .logo, body[data-layout="full"][data-leftbar-theme="light"].hide-menu .wrapper .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-layout-color="dark"][data-layout-mode="boxed"] .wrapper {
  background-color: var(--ct-body-bg);
}

body[data-layout-color="dark"][data-layout="detached"] .leftbar-user .leftbar-user-name {
  color: #f1f1f1;
}

.navbar-custom {
  background-color: var(--ct-bg-topbar);
  box-shadow: var(--ct-box-shadow);
  min-height: 70px;
  z-index: 1001;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
}

.navbar-custom .topbar-left {
  background-color: var(--ct-bg-topbar);
  height: 70px;
  z-index: 1;
  width: 260px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar-custom .topbar-left .logo {
  line-height: 70px;
}

.navbar-custom .topbar-left .logo i {
  display: none;
}

.navbar-custom .topbar-menu {
  z-index: 1;
  position: relative;
}

.navbar-custom .topbar-menu li {
  float: left;
  max-height: 70px;
}

.navbar-custom .topbar-menu li .show.nav-link {
  color: #6c757d;
}

.navbar-custom .topbar-menu .nav-link {
  color: #98a6ad;
  min-width: 32px;
  text-align: center;
  margin: 0 10px;
  padding: 0;
  display: block;
  position: relative;
}

.navbar-custom .app-search {
  position: static;
  overflow-y: hidden;
}

.navbar-custom .app-search form {
  max-width: 320px;
  padding: 16px 5px 16px 0;
  overflow: hidden;
}

.topbar-dropdown .nav-link {
  line-height: 70px;
}

.app-search .form-control {
  height: calc(1.5em + .9rem + 2px);
  background-color: var(--ct-bg-topbar-search);
  box-shadow: none;
  border: none;
  padding-left: 40px;
  padding-right: 20px;
}

.app-search span.search-icon {
  z-index: 4;
  font-size: 20px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 10px;
}

.app-search .input-group-text {
  z-index: 4;
  margin-left: 0;
}

.notification-list {
  margin-left: 0;
}

.notification-list .noti-title {
  background-color: #0000;
  padding: 15px 20px;
}

.notification-list .noti-icon {
  vertical-align: middle;
  font-size: 22px;
  line-height: 70px;
}

.notification-list .noti-icon-badge {
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 6px;
}

.notification-list .notify-item {
  padding: 10px 20px;
}

.notification-list .notify-item.unread-noti {
  background-color: #f3f5fb;
}

.notification-list .notify-item.read-noti {
  background-color: #0000;
  border: 1px solid #eef2f7;
}

.notification-list .notify-item .card-body {
  padding: 14px;
}

.notification-list .notify-item .card-body .noti-close-btn {
  position: absolute;
  top: 3px;
  right: 5px;
}

.notification-list .notify-item .card-body .noti-item-title {
  margin: 0 0 2px;
}

.notification-list .notify-item .card-body .noti-item-title, .notification-list .notify-item .card-body .noti-item-subtitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  line-height: 36px;
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  margin-left: 45px;
  overflow: hidden;
}

.notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
  display: block;
}

.notification-list .notify-item .user-msg {
  white-space: normal;
  margin-left: 45px;
  line-height: 16px;
}

.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.profile-dropdown {
  min-width: 170px;
}

.profile-dropdown i, .profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  min-height: 70px;
  background-color: #fafbfd;
  border: 0 solid #f1f3fa;
  border-width: 0 1px;
  position: relative;
  text-align: left !important;
  padding: 25px 20px 0 !important;
}

.nav-user .account-user-avatar {
  position: absolute;
  top: 19px;
  left: 15px;
}

.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}

.nav-user .account-position {
  margin-top: -3px;
  font-size: 12px;
  display: block;
}

.nav-user .account-user-name {
  font-weight: 600;
  display: block;
}

.button-menu-mobile {
  color: #313a46;
  height: 70px;
  width: 60px;
  cursor: pointer;
  float: left;
  z-index: 1;
  background-color: #0000;
  border: none;
  margin-left: -24px;
  font-size: 24px;
  line-height: 70px;
  position: relative;
}

.button-menu-mobile.disable-btn {
  display: none;
}

@media (max-width: 767.98px) {
  .button-menu-mobile {
    margin: 0 !important;
  }
}

[data-keep-enlarged="true"] .navbar-custom {
  padding-left: 0;
}

[data-keep-enlarged="true"] .button-menu-mobile {
  margin: 0;
}

[data-keep-enlarged="true"] .button-menu-mobile.disable-btn {
  display: inline-block;
}

@media (max-width: 576px) {
  .navbar-custom .topbar-menu {
    position: initial;
  }

  .navbar-custom .dropdown {
    position: static;
  }

  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (max-width: 1200px) {
  .navbar-custom {
    right: 0;
  }

  .navbar-custom .app-search {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .nav-user {
    padding: 17px 5px 17px 57px !important;
  }

  .nav-user .account-position, .nav-user .account-user-name {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-custom {
    padding: 0;
    left: 0 !important;
  }

  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
}

@media (max-width: 375px) {
  .navbar-custom .topbar-menu .nav-link {
    margin: 0 5px;
  }

  .navbar-custom.topnav-navbar .container-fluid, .navbar-custom.topnav-navbar .container-sm, .navbar-custom.topnav-navbar .container-md, .navbar-custom.topnav-navbar .container-lg, .navbar-custom.topnav-navbar .container-xl, .navbar-custom.topnav-navbar .container-xxl {
    padding-left: 12px;
    padding-right: 12px;
  }

  .navbar-custom.topnav-navbar .navbar-toggle {
    margin: 27px 3px;
  }

  .navbar-custom.topnav-navbar .button-menu-mobile {
    width: auto;
  }
}

@media (min-width: 992px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout-mode="boxed"]):not([data-layout="topnav"]) .navbar-custom {
    position: absolute;
  }
}

.topnav-navbar-dark {
  background-color: var(--ct-bg-dark-topbar);
}

.topnav-navbar-dark .nav-user {
  background-color: var(--ct-nav-user-bg-dark-topbar) !important;
  border: 1px solid var(--ct-nav-user-border-dark-topbar) !important;
}

.topnav-navbar-dark .topbar-menu li .show.nav-link {
  color: #fff !important;
}

.topnav-navbar-dark .app-search .form-control {
  background-color: var(--ct-bg-dark-topbar-search);
  color: #fff;
}

.topnav-navbar-dark .app-search span {
  color: #98a6ad;
}

.topnav-navbar-dark .navbar-toggle span {
  background-color: #fffc !important;
}

body[data-layout-mode="boxed"] .navbar-custom {
  margin: -70px -12px 0;
  position: relative;
  left: 0 !important;
}

body[data-layout-mode="boxed"][data-layout="topnav"] .navbar-custom {
  margin: 0;
}

body[data-layout-color="dark"] .navbar-custom .topbar-menu li .show.nav-link {
  color: #dee2e6;
}

body[data-layout-color="dark"] .navbar-custom .topbar-menu .nav-link {
  color: #ced4da;
}

body[data-layout-color="dark"] .notification-list .notify-item.unread-noti {
  background-color: #48515d;
}

body[data-layout-color="dark"] .notification-list .notify-item.read-noti {
  border: 1px solid #464f5b;
}

body[data-layout-color="dark"] .button-menu-mobile {
  color: #f1f1f1;
}

body[data-layout-color="dark"] .nav-user {
  background-color: #464f5b;
  border: 1px solid #404954;
}

body[data-layout-color="dark"] .topnav-navbar-dark .app-search span {
  color: #ced4da;
}

.end-bar {
  background-color: var(--ct-rightbar-bg);
  width: 280px;
  z-index: 9999;
  padding-bottom: 80px;
  transition: all .2s ease-out;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -290px;
  box-shadow: 0 0 24px #313a461a, 0 1px #313a4614;
  float: right !important;
}

.end-bar .rightbar-title {
  background-color: var(--ct-rightbar-title-bg);
  color: var(--ct-rightbar-title-color);
  padding: 27px 25px;
}

.end-bar .end-bar-toggle {
  background-color: var(--ct-rightbar-title-btn-bg);
  height: 24px;
  width: 24px;
  color: var(--ct-rightbar-title-btn-color);
  text-align: center;
  border-radius: 50%;
  margin-top: -4px;
  line-height: 22px;
}

.rightbar-overlay {
  background-color: var(--ct-rightbar-overlay-bg);
  opacity: .2;
  z-index: 9998;
  transition: all .2s ease-out;
  display: none;
  position: absolute;
  inset: 0;
}

.end-bar-enabled .end-bar {
  right: 0;
}

.end-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .end-bar {
    overflow: auto;
  }
}

.page-title-box .page-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--ct-page-title-color);
  margin: 0;
  font-size: 18px;
  line-height: 75px;
  overflow: hidden;
}

.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}

.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  margin-bottom: 25px;
  line-height: 1 !important;
}

.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}

.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title, .text-title:hover {
  color: var(--ct-text-title-color);
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 70px;
    display: block;
    overflow: hidden;
  }

  .page-title-box .breadcrumb, .page-title-box .page-title-right {
    display: none;
  }
}

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}

.footer {
  color: #98a6ad;
  border-top: 1px solid #98a6ad33;
  padding: 19px 24px 20px;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 260px;
  right: 0;
}

.footer .footer-links a {
  color: #98a6ad;
  margin-left: 1.5rem;
  transition: all .4s;
}

.footer .footer-links a:hover {
  color: #313a46;
}

.footer .footer-links a:first-of-type {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .footer {
    text-align: center;
    left: 0 !important;
  }
}

.footer-alt {
  text-align: center;
  border: none;
  left: 0;
}

body[data-layout="topnav"] .footer {
  padding: 19px 0 20px;
  left: 0 !important;
}

body[data-layout="topnav"][data-layout-mode="boxed"] .footer {
  max-width: 1300px;
}

body[data-layout-mode="boxed"] .footer {
  box-shadow: var(--ct-box-shadow);
  max-width: 1040px;
  background-color: #fafbfe;
  border: none;
  margin: 0 auto;
}

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .footer {
  max-width: 1230px;
}

body[data-layout="detached"] .footer {
  left: 0;
}

body[data-layout="detached"] .footer .container-fluid, body[data-layout="detached"] .footer .container-sm, body[data-layout="detached"] .footer .container-md, body[data-layout="detached"] .footer .container-lg, body[data-layout="detached"] .footer .container-xl, body[data-layout="detached"] .footer .container-xxl {
  max-width: 100%;
  padding: 0;
}

body[data-layout-color="dark"] .footer {
  color: #ced4da;
  border-top: 1px solid #ced4da33;
}

body[data-layout-color="dark"] .footer .footer-links a {
  color: #ced4da;
}

body[data-layout-color="dark"] .footer .footer-links a:hover {
  color: #f1f1f1;
}

body[data-layout-color="dark"][data-layout-mode="boxed"] .footer {
  background-color: var(--ct-body-bg);
}

.topnav-navbar {
  min-height: 70px;
  z-index: 1002;
  margin: 0;
  padding: 0;
  position: relative;
  left: 0 !important;
}

.topnav-navbar .topnav-logo {
  float: left;
  min-width: 160px;
  padding-right: 20px;
  line-height: 70px;
}

.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}

.topnav-navbar .navbar-toggle {
  cursor: pointer;
  float: left;
  background-color: #0000;
  border: none;
  margin: 27px 20px;
  padding: 0;
  position: relative;
}

.topnav-navbar .navbar-toggle .lines {
  width: 25px;
  height: 16px;
  transition: all .5s;
  display: block;
  position: relative;
}

.topnav-navbar .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #313a46cc;
  margin-bottom: 5px;
  transition: transform .5s;
  display: block;
}

.topnav-navbar .navbar-toggle.open span {
  position: absolute;
}

.topnav-navbar .navbar-toggle.open span:first-child {
  top: 7px;
  transform: rotate(45deg);
}

.topnav-navbar .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

.topnav-navbar .navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  transform: rotate(-45deg);
}

.topnav-navbar .app-search {
  float: left;
}

.topnav {
  background: var(--ct-bg-topnav);
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  padding: 1rem 1.3rem;
  font-size: .9375rem;
  position: relative;
}

.topnav .nav-item.active > a {
  color: var(--ct-menu-item-active);
}

.topnav .navbar-dark .dropdown.active > .nav-link, .topnav .navbar-dark .dropdown:active > .nav-link {
  color: #fff;
}

.arrow-down {
  display: inline-block;
}

.arrow-down:after {
  border-color: initial;
  content: "";
  height: .4em;
  transform-origin: top;
  width: .4em;
  border-style: solid;
  border-width: 0 0 1px 1px;
  margin-left: 10px;
  transition: all .3s ease-out;
  display: inline-block;
  top: 50%;
  right: 5px;
  transform: rotate(-45deg)translateY(-50%);
}

body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 992px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 95%;
  }

  body[data-layout="topnav"][data-layout-mode="boxed"] .container-fluid, body[data-layout="topnav"][data-layout-mode="boxed"] .container-sm, body[data-layout="topnav"][data-layout-mode="boxed"] .container-md, body[data-layout="topnav"][data-layout-mode="boxed"] .container-lg, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xl, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xxl {
    max-width: 97%;
  }

  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }

  .topnav .dropdown .dropdown-menu {
    min-width: 11.5rem;
    border-radius: 0 0 .25rem .25rem;
    margin-top: 0;
    font-size: .89rem;
  }

  .topnav .dropdown .dropdown-menu .arrow-down:after {
    position: absolute;
    right: 15px;
    transform: rotate(-135deg)translateY(-50%);
  }

  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
  }

  .topnav .dropdown:hover > .dropdown-menu, .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .dropdown.active > a.dropdown-item {
    color: var(--ct-dropdown-link-active-color);
    background-color: var(--ct-dropdown-link-active-bg);
  }
}

@media (min-width: 1400px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 85%;
  }
}

@media (min-width: 992px) {
  .navbar-toggle {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .topnav-navbar .topnav-logo-lg {
    display: none;
  }

  .topnav-navbar .topnav-logo {
    min-width: 50px;
    text-align: center;
    padding-right: 0;
  }

  .topnav-navbar .topnav-logo-sm {
    display: block !important;
  }

  .topnav .navbar-nav .nav-link {
    padding: .75rem 1.3rem;
  }

  .topnav .dropdown .dropdown-menu {
    box-shadow: none;
    background-color: #0000;
    border: none;
    padding-left: 15px;
  }

  .topnav .dropdown .dropdown-item {
    background-color: #0000;
    position: relative;
  }

  .topnav .navbar-dark .dropdown .dropdown-item {
    color: #ffffff80;
  }

  .topnav .navbar-dark .dropdown .dropdown-item.active, .topnav .navbar-dark .dropdown .dropdown-item:active {
    color: #fff;
  }

  .topnav .arrow-down:after {
    position: absolute;
    right: 15px;
  }
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Light.906ca4b9.eot");
  src: local(Nunito Light), local(Nunito-Light), url("Nunito-Light.906ca4b9.eot#iefix") format("embedded-opentype"), url("Nunito-Light.d88c117f.woff") format("woff"), url("Nunito-Light.0a762398.ttf") format("truetype"), url("Nunito-Light.f97836de.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Regular.dc159d5b.eot");
  src: local(Nunito Regular), local(Nunito-Regular), url("Nunito-Regular.dc159d5b.eot#iefix") format("embedded-opentype"), url("Nunito-Regular.a34e1dea.woff") format("woff"), url("Nunito-Regular.4c8bbaa7.ttf") format("truetype"), url("Nunito-Regular.98460481.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-SemiBold.a2ff68b5.eot");
  src: local(Nunito SemiBold), local(Nunito-SemiBold), url("Nunito-SemiBold.a2ff68b5.eot#iefix") format("embedded-opentype"), url("Nunito-SemiBold.12fa0786.woff") format("woff"), url("Nunito-SemiBold.78e39110.ttf") format("truetype"), url("Nunito-SemiBold.ddc593e2.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Bold.80c3347d.eot");
  src: local(Nunito Bold), local(Nunito-Bold), url("Nunito-Bold.80c3347d.eot#iefix") format("embedded-opentype"), url("Nunito-Bold.4e1586f5.woff") format("woff"), url("Nunito-Bold.860435f7.ttf") format("truetype"), url("Nunito-Bold.c6f7514e.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}

.custom-accordion .card {
  box-shadow: none;
}

.custom-accordion .card-header {
  background-color: #f1f3fa;
}

.custom-accordion .card-body {
  border: 1px solid #f1f3fa;
}

.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}

.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "";
}

.custom-accordion-title {
  color: var(--ct-custom-accordion-title-color);
  position: relative;
}

.custom-accordion-title:hover {
  color: #414d5d;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

body[data-layout-color="dark"] .custom-accordion .card-header {
  background-color: #404954;
}

body[data-layout-color="dark"] .custom-accordion .card-body {
  border: 1px solid #404954;
}

body[data-layout-color="dark"] .custom-accordion-title:hover {
  color: #99a4b2;
}

.alert-primary {
  color: #1f2042;
  background-color: #2122452e;
  border-color: #21224540;
}

.alert-primary .alert-link {
  color: #171830;
}

.alert-secondary {
  color: #676f77;
  background-color: #6c757d2e;
  border-color: #6c757d40;
}

.alert-secondary .alert-link {
  color: #4c5258;
}

.alert-success {
  color: #0ac58f;
  background-color: #0acf972e;
  border-color: #0acf9740;
}

.alert-success .alert-link {
  color: #07916a;
}

.alert-info {
  color: #36a6c7;
  background-color: #39afd12e;
  border-color: #39afd140;
}

.alert-info .alert-link {
  color: #287b92;
}

.alert-warning {
  color: #f2b300;
  background-color: #ffbc002e;
  border-color: #ffbc0040;
}

.alert-warning .alert-link {
  color: #b38400;
}

.alert-danger {
  color: #ee5776;
  background-color: #fa5c7c2e;
  border-color: #fa5c7c40;
}

.alert-danger .alert-link {
  color: #af4057;
}

.alert-light {
  color: #e2e6eb;
  background-color: #eef2f72e;
  border-color: #eef2f740;
}

.alert-light .alert-link {
  color: #a7a9ad;
}

.alert-dark {
  color: #2f3743;
  background-color: #313a462e;
  border-color: #313a4640;
}

.alert-dark .alert-link {
  color: #222931;
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  color: #fff;
  height: 100%;
  width: 100%;
  background-color: #212245;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item:before {
  font-family: Material Design Icons;
  font-size: 16px;
  line-height: 1.3;
}

.btn .mdi:before {
  line-height: initial;
}

.btn-primary {
  box-shadow: 0 2px 6px #21224580;
}

.btn-secondary {
  box-shadow: 0 2px 6px #6c757d80;
}

.btn-success {
  box-shadow: 0 2px 6px #0acf9780;
}

.btn-info {
  box-shadow: 0 2px 6px #39afd180;
}

.btn-warning {
  box-shadow: 0 2px 6px #ffbc0080;
}

.btn-danger {
  box-shadow: 0 2px 6px #fa5c7c80;
}

.btn-light {
  box-shadow: 0 2px 6px #eef2f780;
}

.btn-dark {
  box-shadow: 0 2px 6px #313a4680;
}

body[data-layout-color="dark"] .btn-primary {
  box-shadow: 0 2px 6px #21224580;
}

body[data-layout-color="dark"] .btn-secondary {
  box-shadow: 0 2px 6px #ced4da80;
}

body[data-layout-color="dark"] .btn-success {
  box-shadow: 0 2px 6px #0acf9780;
}

body[data-layout-color="dark"] .btn-info {
  box-shadow: 0 2px 6px #39afd180;
}

body[data-layout-color="dark"] .btn-warning {
  box-shadow: 0 2px 6px #ffbc0080;
}

body[data-layout-color="dark"] .btn-danger {
  box-shadow: 0 2px 6px #fa5c7c80;
}

body[data-layout-color="dark"] .btn-light {
  box-shadow: 0 2px 6px #464f5b80;
}

body[data-layout-color="dark"] .btn-dark {
  box-shadow: 0 2px 6px #f1f1f180;
}

body[data-layout-color="dark"] .btn-light {
  color: #f1f1f1;
  background-color: #464f5b;
  border-color: #464f5b;
}

body[data-layout-color="dark"] .btn-dark {
  color: #404954;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

body[data-layout-color="dark"] .btn-secondary {
  color: #464f5b;
  background-color: #ced4da;
  border-color: #ced4da;
}

body[data-layout-color="dark"] .btn-outline-light {
  color: #464f5b;
  border-color: #464f5b;
}

body[data-layout-color="dark"] .btn-outline-light:hover, body[data-layout-color="dark"] .btn-outline-light.active, body[data-layout-color="dark"] .btn-outline-light:active, body[data-layout-color="dark"] .btn-outline-light:focus {
  background-color: #464f5b;
  color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .btn-outline-dark {
  color: #f1f1f1;
  border-color: #f1f1f1;
}

body[data-layout-color="dark"] .btn-outline-dark:hover, body[data-layout-color="dark"] .btn-outline-dark.active, body[data-layout-color="dark"] .btn-outline-dark:active, body[data-layout-color="dark"] .btn-outline-dark:focus {
  background-color: #f1f1f1;
  color: #404954 !important;
}

body[data-layout-color="dark"] .btn-outline-secondary {
  color: #ced4da;
  border-color: #ced4da;
}

body[data-layout-color="dark"] .btn-outline-secondary:hover, body[data-layout-color="dark"] .btn-outline-secondary.active, body[data-layout-color="dark"] .btn-outline-secondary:active, body[data-layout-color="dark"] .btn-outline-secondary:focus {
  background-color: #ced4da;
  color: #464f5b !important;
}

.badge-primary-lighten {
  color: #212245;
  background-color: #2122452e;
}

.badge-primary-lighten[href] {
  color: #212245;
  background-color: #2122452e;
  text-decoration: none;
}

.badge-primary-lighten[href]:hover, .badge-primary-lighten[href]:focus {
  color: #212245;
  background-color: #21224566;
  text-decoration: none;
}

.badge-secondary-lighten {
  color: #6c757d;
  background-color: #6c757d2e;
}

.badge-secondary-lighten[href] {
  color: #6c757d;
  background-color: #6c757d2e;
  text-decoration: none;
}

.badge-secondary-lighten[href]:hover, .badge-secondary-lighten[href]:focus {
  color: #6c757d;
  background-color: #6c757d66;
  text-decoration: none;
}

.badge-success-lighten {
  color: #0acf97;
  background-color: #0acf972e;
}

.badge-success-lighten[href] {
  color: #0acf97;
  background-color: #0acf972e;
  text-decoration: none;
}

.badge-success-lighten[href]:hover, .badge-success-lighten[href]:focus {
  color: #0acf97;
  background-color: #0acf9766;
  text-decoration: none;
}

.badge-info-lighten {
  color: #39afd1;
  background-color: #39afd12e;
}

.badge-info-lighten[href] {
  color: #39afd1;
  background-color: #39afd12e;
  text-decoration: none;
}

.badge-info-lighten[href]:hover, .badge-info-lighten[href]:focus {
  color: #39afd1;
  background-color: #39afd166;
  text-decoration: none;
}

.badge-warning-lighten {
  color: #ffbc00;
  background-color: #ffbc002e;
}

.badge-warning-lighten[href] {
  color: #ffbc00;
  background-color: #ffbc002e;
  text-decoration: none;
}

.badge-warning-lighten[href]:hover, .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0066;
  text-decoration: none;
}

.badge-danger-lighten {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
}

.badge-danger-lighten[href] {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
  text-decoration: none;
}

.badge-danger-lighten[href]:hover, .badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c66;
  text-decoration: none;
}

.badge-light-lighten {
  color: #eef2f7;
  background-color: #eef2f72e;
}

.badge-light-lighten[href] {
  color: #eef2f7;
  background-color: #eef2f72e;
  text-decoration: none;
}

.badge-light-lighten[href]:hover, .badge-light-lighten[href]:focus {
  color: #eef2f7;
  background-color: #eef2f766;
  text-decoration: none;
}

.badge-dark-lighten {
  color: #313a46;
  background-color: #313a462e;
}

.badge-dark-lighten[href] {
  color: #313a46;
  background-color: #313a462e;
  text-decoration: none;
}

.badge-dark-lighten[href]:hover, .badge-dark-lighten[href]:focus {
  color: #313a46;
  background-color: #313a4666;
  text-decoration: none;
}

.badge-outline-primary {
  color: #212245;
  background-color: #0000;
  border: 1px solid #212245;
}

.badge-outline-primary[href], .badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #212245;
  background-color: #21224533;
  text-decoration: none;
}

.badge-outline-secondary {
  color: #6c757d;
  background-color: #0000;
  border: 1px solid #6c757d;
}

.badge-outline-secondary[href], .badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
  color: #6c757d;
  background-color: #6c757d33;
  text-decoration: none;
}

.badge-outline-success {
  color: #0acf97;
  background-color: #0000;
  border: 1px solid #0acf97;
}

.badge-outline-success[href], .badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
  color: #0acf97;
  background-color: #0acf9733;
  text-decoration: none;
}

.badge-outline-info {
  color: #39afd1;
  background-color: #0000;
  border: 1px solid #39afd1;
}

.badge-outline-info[href], .badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
  color: #39afd1;
  background-color: #39afd133;
  text-decoration: none;
}

.badge-outline-warning {
  color: #ffbc00;
  background-color: #0000;
  border: 1px solid #ffbc00;
}

.badge-outline-warning[href], .badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0033;
  text-decoration: none;
}

.badge-outline-danger {
  color: #fa5c7c;
  background-color: #0000;
  border: 1px solid #fa5c7c;
}

.badge-outline-danger[href], .badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  text-decoration: none;
}

.badge-outline-light {
  color: #eef2f7;
  background-color: #0000;
  border: 1px solid #eef2f7;
}

.badge-outline-light[href], .badge-outline-light[href]:hover, .badge-outline-light[href]:focus {
  color: #eef2f7;
  background-color: #eef2f733;
  text-decoration: none;
}

.badge-outline-dark {
  color: #313a46;
  background-color: #0000;
  border: 1px solid #313a46;
}

.badge-outline-dark[href], .badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
  color: #313a46;
  background-color: #313a4633;
  text-decoration: none;
}

.badge {
  vertical-align: middle;
}

.badge.badge-lg {
  padding: .5em 1em;
  font-size: .85em;
}

body[data-layout-color="dark"] .badge-primary-lighten {
  color: #212245;
  background-color: #2122452e;
}

body[data-layout-color="dark"] .badge-primary-lighten[href] {
  color: #212245;
  background-color: #2122452e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-primary-lighten[href]:hover, body[data-layout-color="dark"] .badge-primary-lighten[href]:focus {
  color: #212245;
  background-color: #21224566;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-secondary-lighten {
  color: #ced4da;
  background-color: #ced4da2e;
}

body[data-layout-color="dark"] .badge-secondary-lighten[href] {
  color: #ced4da;
  background-color: #ced4da2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-secondary-lighten[href]:hover, body[data-layout-color="dark"] .badge-secondary-lighten[href]:focus {
  color: #ced4da;
  background-color: #ced4da66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-success-lighten {
  color: #0acf97;
  background-color: #0acf972e;
}

body[data-layout-color="dark"] .badge-success-lighten[href] {
  color: #0acf97;
  background-color: #0acf972e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-success-lighten[href]:hover, body[data-layout-color="dark"] .badge-success-lighten[href]:focus {
  color: #0acf97;
  background-color: #0acf9766;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-info-lighten {
  color: #39afd1;
  background-color: #39afd12e;
}

body[data-layout-color="dark"] .badge-info-lighten[href] {
  color: #39afd1;
  background-color: #39afd12e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-info-lighten[href]:hover, body[data-layout-color="dark"] .badge-info-lighten[href]:focus {
  color: #39afd1;
  background-color: #39afd166;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-warning-lighten {
  color: #ffbc00;
  background-color: #ffbc002e;
}

body[data-layout-color="dark"] .badge-warning-lighten[href] {
  color: #ffbc00;
  background-color: #ffbc002e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-warning-lighten[href]:hover, body[data-layout-color="dark"] .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0066;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-danger-lighten {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
}

body[data-layout-color="dark"] .badge-danger-lighten[href] {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-danger-lighten[href]:hover, body[data-layout-color="dark"] .badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-light-lighten {
  color: #464f5b;
  background-color: #464f5b2e;
}

body[data-layout-color="dark"] .badge-light-lighten[href] {
  color: #464f5b;
  background-color: #464f5b2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-light-lighten[href]:hover, body[data-layout-color="dark"] .badge-light-lighten[href]:focus {
  color: #464f5b;
  background-color: #464f5b66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-dark-lighten {
  color: #f1f1f1;
  background-color: #f1f1f12e;
}

body[data-layout-color="dark"] .badge-dark-lighten[href] {
  color: #f1f1f1;
  background-color: #f1f1f12e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-dark-lighten[href]:hover, body[data-layout-color="dark"] .badge-dark-lighten[href]:focus {
  color: #f1f1f1;
  background-color: #f1f1f166;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-primary {
  color: #212245;
  background-color: #0000;
  border: 1px solid #212245;
}

body[data-layout-color="dark"] .badge-outline-primary[href], body[data-layout-color="dark"] .badge-outline-primary[href]:hover, body[data-layout-color="dark"] .badge-outline-primary[href]:focus {
  color: #212245;
  background-color: #21224533;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-secondary {
  color: #ced4da;
  background-color: #0000;
  border: 1px solid #ced4da;
}

body[data-layout-color="dark"] .badge-outline-secondary[href], body[data-layout-color="dark"] .badge-outline-secondary[href]:hover, body[data-layout-color="dark"] .badge-outline-secondary[href]:focus {
  color: #ced4da;
  background-color: #ced4da33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-success {
  color: #0acf97;
  background-color: #0000;
  border: 1px solid #0acf97;
}

body[data-layout-color="dark"] .badge-outline-success[href], body[data-layout-color="dark"] .badge-outline-success[href]:hover, body[data-layout-color="dark"] .badge-outline-success[href]:focus {
  color: #0acf97;
  background-color: #0acf9733;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-info {
  color: #39afd1;
  background-color: #0000;
  border: 1px solid #39afd1;
}

body[data-layout-color="dark"] .badge-outline-info[href], body[data-layout-color="dark"] .badge-outline-info[href]:hover, body[data-layout-color="dark"] .badge-outline-info[href]:focus {
  color: #39afd1;
  background-color: #39afd133;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-warning {
  color: #ffbc00;
  background-color: #0000;
  border: 1px solid #ffbc00;
}

body[data-layout-color="dark"] .badge-outline-warning[href], body[data-layout-color="dark"] .badge-outline-warning[href]:hover, body[data-layout-color="dark"] .badge-outline-warning[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0033;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-danger {
  color: #fa5c7c;
  background-color: #0000;
  border: 1px solid #fa5c7c;
}

body[data-layout-color="dark"] .badge-outline-danger[href], body[data-layout-color="dark"] .badge-outline-danger[href]:hover, body[data-layout-color="dark"] .badge-outline-danger[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-light {
  color: #464f5b;
  background-color: #0000;
  border: 1px solid #464f5b;
}

body[data-layout-color="dark"] .badge-outline-light[href], body[data-layout-color="dark"] .badge-outline-light[href]:hover, body[data-layout-color="dark"] .badge-outline-light[href]:focus {
  color: #464f5b;
  background-color: #464f5b33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-dark {
  color: #f1f1f1;
  background-color: #0000;
  border: 1px solid #f1f1f1;
}

body[data-layout-color="dark"] .badge-outline-dark[href], body[data-layout-color="dark"] .badge-outline-dark[href]:hover, body[data-layout-color="dark"] .badge-outline-dark[href]:focus {
  color: #f1f1f1;
  background-color: #f1f1f133;
  text-decoration: none;
}

.card {
  box-shadow: var(--ct-card-box-shadow);
  border: none;
  margin-bottom: 24px;
}

.card .header-title {
  text-transform: uppercase;
  letter-spacing: .02em;
  margin-top: 0;
  margin-bottom: .5rem;
  font-size: .9rem;
}

.card .card-drop {
  color: inherit;
  font-size: 20px;
}

.card .card-widgets {
  float: right;
  height: 16px;
}

.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.card .card-widgets > a.collapsed i:before {
  content: "󰐕";
}

.card-title, .card-header {
  margin-top: 0;
}

.card-disabled {
  cursor: progress;
  background: #f1f3facc;
  border-radius: .25rem;
  position: absolute;
  inset: 0;
}

.card-disabled .card-portlets-loader {
  height: 30px;
  width: 30px;
  background-color: #313a46;
  margin-top: -12px;
  margin-left: -12px;
  animation: rotatebox 1.2s ease-in-out infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px)rotateX(0deg)rotateY(0deg);
  }

  50% {
    transform: perspective(120px)rotateX(-180.1deg)rotateY(0deg);
  }

  100% {
    transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
  }
}

.card-pricing {
  position: relative;
}

.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}

.card-pricing .card-pricing-icon {
  box-shadow: var(--ct-box-shadow);
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 56px;
  display: inline-block;
}

.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}

.card-pricing .card-pricing-price span {
  color: #98a6ad;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 40%;
}

.card-pricing .card-pricing-features {
  color: #98a6ad;
  margin: 0;
  padding: 20px 0 0;
  list-style: none;
}

.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (min-width: 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}

.card-pricing-recommended .card-pricing-plan-tag {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  border-radius: .25rem .25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem;
  padding: 5px 0;
  font-weight: 700;
}

.card-h-100 {
  height: calc(100% - 24px);
}

body[data-layout-color="dark"] .card-pricing .card-pricing-price span, body[data-layout-color="dark"] .card-pricing .card-pricing-features {
  color: #ced4da;
}

body[data-layout-color="dark"] .card-disabled {
  background: #404954cc;
}

body[data-layout-color="dark"] .card-disabled .card-portlets-loader {
  background-color: #f1f1f1;
}

.form-checkbox-primary .form-check-input:checked, .form-radio-primary .form-check-input:checked {
  background-color: #212245;
  border-color: #212245;
}

.form-checkbox-secondary .form-check-input:checked, .form-radio-secondary .form-check-input:checked {
  background-color: #6c757d;
  border-color: #6c757d;
}

.form-checkbox-success .form-check-input:checked, .form-radio-success .form-check-input:checked {
  background-color: #0acf97;
  border-color: #0acf97;
}

.form-checkbox-info .form-check-input:checked, .form-radio-info .form-check-input:checked {
  background-color: #39afd1;
  border-color: #39afd1;
}

.form-checkbox-warning .form-check-input:checked, .form-radio-warning .form-check-input:checked {
  background-color: #ffbc00;
  border-color: #ffbc00;
}

.form-checkbox-danger .form-check-input:checked, .form-radio-danger .form-check-input:checked {
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.form-checkbox-light .form-check-input:checked, .form-radio-light .form-check-input:checked {
  background-color: #eef2f7;
  border-color: #eef2f7;
}

.form-checkbox-dark .form-check-input:checked, .form-radio-dark .form-check-input:checked {
  background-color: #313a46;
  border-color: #313a46;
}

.dropdown-menu {
  box-shadow: var(--ct-box-shadow);
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  z-index: 1000;
  margin: 0;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  position: absolute;
}

.dropdown-menu-animated.show {
  top: 100% !important;
}

.dropdown-menu-animated i {
  display: inline-block;
}

.dropdown-menu-animated.dropdown-menu[data-popper-placement^="right"], .dropdown-menu-animated.dropdown-menu[data-popper-placement^="top"], .dropdown-menu-animated.dropdown-menu[data-popper-placement^="left"] {
  animation: none !important;
  top: auto !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

@media (min-width: 576px) {
  .dropdown-lg {
    width: 320px;
  }
}

.dropdown-icon-item {
  text-align: center;
  color: var(--ct-dropdown-link-color);
  border: 1px solid #0000;
  border-radius: 3px;
  padding: 15px 0 9px;
  line-height: 34px;
  display: block;
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.dropdown-icon-item:hover {
  background-color: var(--ct-dropdown-link-hover-bg);
  color: var(--ct-dropdown-link-hover-color);
}

.arrow-none:after {
  display: none;
}

.hljs {
  color: #313a46;
  max-height: 420px;
  border: 1px solid #98a6ad33;
  margin: -10px 0 -30px;
  padding: 2em;
  display: block;
  overflow-x: auto;
}

.hljs-keyword, .hljs-selector-tag, .hljs-subst {
  color: #313a46;
  font-weight: bold;
}

.hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr {
  color: #02a8b5;
}

.hljs-string, .hljs-doctag {
  color: #fa5c7c;
}

.hljs-title, .hljs-section, .hljs-selector-id {
  color: #fa5c7c;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-tag, .hljs-name, .hljs-attribute {
  color: #0768d1;
  font-weight: normal;
}

.hljs-regexp, .hljs-link {
  color: #02a8b5;
}

.hljs-symbol, .hljs-bullet {
  color: #ff679b;
}

.hljs-built_in, .hljs-builtin-name {
  color: #39afd1;
}

.hljs-meta {
  color: #343a40;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-comment {
  color: #ced4da;
}

body[data-layout-color="dark"] .hljs {
  border: 1px solid #ced4da33;
}

body[data-layout-color="dark"] .hljs, body[data-layout-color="dark"] .hljs-keyword, body[data-layout-color="dark"] .hljs-selector-tag, body[data-layout-color="dark"] .hljs-subst {
  color: #f1f1f1;
}

body[data-layout-color="dark"] .hljs-meta {
  color: #e3eaef;
}

body[data-layout-color="dark"] .hljs-comment {
  color: #8391a2;
}

.form-control-light {
  background-color: #f1f3fa !important;
  border-color: #f1f3fa !important;
}

input.form-control[type="color"], input.form-control[type="range"] {
  min-height: 39px;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus, .custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + .9rem + 2px);
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + .56rem + 2px);
}

.password-eye:before {
  content: "󰛐";
  font-variant: normal;
  vertical-align: middle;
  font-family: Material Design Icons;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.show-password .password-eye:before {
  content: "󰛑";
}

body[data-layout-color="dark"] .form-control-light {
  background-color: #404954 !important;
  border-color: #404954 !important;
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  height: 100%;
  background-color: var(--ct-modal-content-bg);
  flex-flow: column;
  place-content: center;
  margin: 0;
  display: flex;
  position: absolute;
  right: 0;
  transform: translate(25%) !important;
}

.modal-right button.btn-close {
  z-index: 1;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal.show .modal-right, .modal.show .modal-left {
  transform: translate(0) !important;
}

.modal-bottom {
  height: 100%;
  flex-flow: column;
  place-content: center flex-end;
  margin: 0 auto;
  display: flex;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}

.modal-colored-header .btn-close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}

.modal-filled .modal-header {
  background-color: #ffffff12;
}

.modal-filled .modal-header, .modal-filled .modal-footer {
  border: none;
}

.modal-filled .btn-close {
  color: #fff !important;
}

.nav-tabs > li > a, .nav-pills > li > a, .nav-pills > a {
  color: #6c757d;
  font-weight: 600;
}

.bg-nav-pills {
  background-color: var(--ct-nav-pills-bg);
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid #98a6ad33;
}

.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -1px;
}

.nav-tabs.nav-bordered li a {
  border: 0;
  padding: .625rem 1.25rem;
}

.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid #212245;
}

body[data-layout-color="dark"] .nav-tabs > li > a, body[data-layout-color="dark"] .nav-pills > li > a, body[data-layout-color="dark"] .nav-pills > a {
  color: #dee2e6;
}

body[data-layout-color="dark"] .nav-tabs.nav-bordered {
  border-bottom: 2px solid #ced4da33;
}

.pagination-rounded .page-link {
  border: none;
  border-radius: 30px !important;
  margin: 0 3px !important;
}

.popover-header {
  margin-top: 0;
}

@media print {
  .leftside-menu, .end-bar, .page-title-box, .navbar-custom, .footer {
    display: none;
  }

  .card-body, .content-page, .end-bar, .content, body {
    margin: 0;
    padding: 0;
  }
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body.loading {
  visibility: hidden;
}

button, a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b, strong {
  font-weight: 700;
}

.ribbon-box {
  position: relative;
}

.ribbon-box .ribbon {
  clear: both;
  color: #fff;
  margin-bottom: 15px;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  box-shadow: 2px 5px 10px #313a4626;
}

.ribbon-box .ribbon:before {
  content: " ";
  z-index: -1;
  border-style: solid;
  border-width: 10px;
  margin-bottom: -10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.ribbon-box .ribbon.float-start {
  border-radius: 0 3px 3px 0;
  margin-left: -30px;
}

.ribbon-box .ribbon.float-end {
  border-radius: 3px 0 0 3px;
  margin-right: -30px;
}

.ribbon-box .ribbon.float-end:before {
  right: 0;
}

.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px;
}

.ribbon-box .ribbon-content {
  clear: both;
}

.ribbon-box .ribbon-primary {
  background: #212245;
}

.ribbon-box .ribbon-primary:before {
  border-color: #191a34 #0000 #0000;
}

.ribbon-box .ribbon-secondary {
  background: #6c757d;
}

.ribbon-box .ribbon-secondary:before {
  border-color: #60686f #0000 #0000;
}

.ribbon-box .ribbon-success {
  background: #0acf97;
}

.ribbon-box .ribbon-success:before {
  border-color: #09b785 #0000 #0000;
}

.ribbon-box .ribbon-info {
  background: #39afd1;
}

.ribbon-box .ribbon-info:before {
  border-color: #2da2c3 #0000 #0000;
}

.ribbon-box .ribbon-warning {
  background: #ffbc00;
}

.ribbon-box .ribbon-warning:before {
  border-color: #e6a900 #0000 #0000;
}

.ribbon-box .ribbon-danger {
  background: #fa5c7c;
}

.ribbon-box .ribbon-danger:before {
  border-color: #f94368 #0000 #0000;
}

.ribbon-box .ribbon-light {
  background: #eef2f7;
}

.ribbon-box .ribbon-light:before {
  border-color: #dde5ef #0000 #0000;
}

.ribbon-box .ribbon-dark {
  background: #313a46;
}

.ribbon-box .ribbon-dark:before {
  border-color: #272e37 #0000 #0000;
}

.ribbon-box .ribbon-two {
  z-index: 1;
  width: 75px;
  height: 75px;
  text-align: right;
  position: absolute;
  top: -5px;
  left: -5px;
  overflow: hidden;
}

.ribbon-box .ribbon-two span {
  color: #fff;
  text-align: center;
  width: 100px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  position: absolute;
  top: 19px;
  left: -21px;
  transform: rotate(-45deg);
  box-shadow: 0 0 8px #313a4614, 0 1px #313a4608;
}

.ribbon-box .ribbon-two span:before {
  content: "";
  z-index: -1;
  border-bottom: 3px solid #0000;
  border-right: 3px solid #0000;
  position: absolute;
  top: 100%;
  left: 0;
}

.ribbon-box .ribbon-two span:after {
  content: "";
  z-index: -1;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  position: absolute;
  top: 100%;
  right: 0;
}

.ribbon-box .ribbon-two-primary span {
  background: #212245;
}

.ribbon-box .ribbon-two-primary span:before {
  border-top: 3px solid #191a34;
  border-left: 3px solid #191a34;
}

.ribbon-box .ribbon-two-primary span:after {
  border-top: 3px solid #191a34;
  border-right: 3px solid #191a34;
}

.ribbon-box .ribbon-two-secondary span {
  background: #6c757d;
}

.ribbon-box .ribbon-two-secondary span:before {
  border-top: 3px solid #60686f;
  border-left: 3px solid #60686f;
}

.ribbon-box .ribbon-two-secondary span:after {
  border-top: 3px solid #60686f;
  border-right: 3px solid #60686f;
}

.ribbon-box .ribbon-two-success span {
  background: #0acf97;
}

.ribbon-box .ribbon-two-success span:before {
  border-top: 3px solid #09b785;
  border-left: 3px solid #09b785;
}

.ribbon-box .ribbon-two-success span:after {
  border-top: 3px solid #09b785;
  border-right: 3px solid #09b785;
}

.ribbon-box .ribbon-two-info span {
  background: #39afd1;
}

.ribbon-box .ribbon-two-info span:before {
  border-top: 3px solid #2da2c3;
  border-left: 3px solid #2da2c3;
}

.ribbon-box .ribbon-two-info span:after {
  border-top: 3px solid #2da2c3;
  border-right: 3px solid #2da2c3;
}

.ribbon-box .ribbon-two-warning span {
  background: #ffbc00;
}

.ribbon-box .ribbon-two-warning span:before {
  border-top: 3px solid #e6a900;
  border-left: 3px solid #e6a900;
}

.ribbon-box .ribbon-two-warning span:after {
  border-top: 3px solid #e6a900;
  border-right: 3px solid #e6a900;
}

.ribbon-box .ribbon-two-danger span {
  background: #fa5c7c;
}

.ribbon-box .ribbon-two-danger span:before {
  border-top: 3px solid #f94368;
  border-left: 3px solid #f94368;
}

.ribbon-box .ribbon-two-danger span:after {
  border-top: 3px solid #f94368;
  border-right: 3px solid #f94368;
}

.ribbon-box .ribbon-two-light span {
  background: #eef2f7;
}

.ribbon-box .ribbon-two-light span:before {
  border-top: 3px solid #dde5ef;
  border-left: 3px solid #dde5ef;
}

.ribbon-box .ribbon-two-light span:after {
  border-top: 3px solid #dde5ef;
  border-right: 3px solid #dde5ef;
}

.ribbon-box .ribbon-two-dark span {
  background: #313a46;
}

.ribbon-box .ribbon-two-dark span:before {
  border-top: 3px solid #272e37;
  border-left: 3px solid #272e37;
}

.ribbon-box .ribbon-two-dark span:after {
  border-top: 3px solid #272e37;
  border-right: 3px solid #272e37;
}

body[data-layout-color="dark"] .ribbon-primary {
  background: #212245;
}

body[data-layout-color="dark"] .ribbon-primary:before {
  border-color: #191a34 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-secondary {
  background: #ced4da;
}

body[data-layout-color="dark"] .ribbon-secondary:before {
  border-color: #bfc7cf #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-success {
  background: #0acf97;
}

body[data-layout-color="dark"] .ribbon-success:before {
  border-color: #09b785 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-info {
  background: #39afd1;
}

body[data-layout-color="dark"] .ribbon-info:before {
  border-color: #2da2c3 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-warning {
  background: #ffbc00;
}

body[data-layout-color="dark"] .ribbon-warning:before {
  border-color: #e6a900 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-danger {
  background: #fa5c7c;
}

body[data-layout-color="dark"] .ribbon-danger:before {
  border-color: #f94368 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-light {
  background: #464f5b;
}

body[data-layout-color="dark"] .ribbon-light:before {
  border-color: #3b424d #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-dark {
  background: #f1f1f1;
}

body[data-layout-color="dark"] .ribbon-dark:before {
  border-color: #e4e4e4 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-two-primary span {
  background: #212245;
}

body[data-layout-color="dark"] .ribbon-two-primary span:before {
  border-top: 3px solid #191a34;
  border-left: 3px solid #191a34;
}

body[data-layout-color="dark"] .ribbon-two-primary span:after {
  border-top: 3px solid #191a34;
  border-right: 3px solid #191a34;
}

body[data-layout-color="dark"] .ribbon-two-secondary span {
  background: #ced4da;
}

body[data-layout-color="dark"] .ribbon-two-secondary span:before {
  border-top: 3px solid #bfc7cf;
  border-left: 3px solid #bfc7cf;
}

body[data-layout-color="dark"] .ribbon-two-secondary span:after {
  border-top: 3px solid #bfc7cf;
  border-right: 3px solid #bfc7cf;
}

body[data-layout-color="dark"] .ribbon-two-success span {
  background: #0acf97;
}

body[data-layout-color="dark"] .ribbon-two-success span:before {
  border-top: 3px solid #09b785;
  border-left: 3px solid #09b785;
}

body[data-layout-color="dark"] .ribbon-two-success span:after {
  border-top: 3px solid #09b785;
  border-right: 3px solid #09b785;
}

body[data-layout-color="dark"] .ribbon-two-info span {
  background: #39afd1;
}

body[data-layout-color="dark"] .ribbon-two-info span:before {
  border-top: 3px solid #2da2c3;
  border-left: 3px solid #2da2c3;
}

body[data-layout-color="dark"] .ribbon-two-info span:after {
  border-top: 3px solid #2da2c3;
  border-right: 3px solid #2da2c3;
}

body[data-layout-color="dark"] .ribbon-two-warning span {
  background: #ffbc00;
}

body[data-layout-color="dark"] .ribbon-two-warning span:before {
  border-top: 3px solid #e6a900;
  border-left: 3px solid #e6a900;
}

body[data-layout-color="dark"] .ribbon-two-warning span:after {
  border-top: 3px solid #e6a900;
  border-right: 3px solid #e6a900;
}

body[data-layout-color="dark"] .ribbon-two-danger span {
  background: #fa5c7c;
}

body[data-layout-color="dark"] .ribbon-two-danger span:before {
  border-top: 3px solid #f94368;
  border-left: 3px solid #f94368;
}

body[data-layout-color="dark"] .ribbon-two-danger span:after {
  border-top: 3px solid #f94368;
  border-right: 3px solid #f94368;
}

body[data-layout-color="dark"] .ribbon-two-light span {
  background: #464f5b;
}

body[data-layout-color="dark"] .ribbon-two-light span:before {
  border-top: 3px solid #3b424d;
  border-left: 3px solid #3b424d;
}

body[data-layout-color="dark"] .ribbon-two-light span:after {
  border-top: 3px solid #3b424d;
  border-right: 3px solid #3b424d;
}

body[data-layout-color="dark"] .ribbon-two-dark span {
  background: #f1f1f1;
}

body[data-layout-color="dark"] .ribbon-two-dark span:before {
  border-top: 3px solid #e4e4e4;
  border-left: 3px solid #e4e4e4;
}

body[data-layout-color="dark"] .ribbon-two-dark span:after {
  border-top: 3px solid #e4e4e4;
  border-right: 3px solid #e4e4e4;
}

input[data-switch] {
  display: none;
}

input[data-switch] + label {
  width: 56px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  background-color: #f1f3fa;
  background-image: none;
  border-radius: 2rem;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
}

input[data-switch] + label:before {
  color: #313a46;
  content: attr(data-off-label);
  text-align: center;
  min-width: 1.66667rem;
  margin: 0 .21667rem;
  font-family: inherit;
  font-size: .75rem;
  font-weight: 600;
  line-height: 24px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  right: 3px;
  overflow: hidden;
}

input[data-switch] + label:after {
  content: "";
  box-shadow: none;
  height: 18px;
  width: 18px;
  background-color: #adb5bd;
  border-radius: 2rem;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 3px;
  left: 4px;
}

input[data-switch]:checked + label {
  background-color: #212245;
}

input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  left: 4px;
  right: auto;
}

input[data-switch]:checked + label:after {
  background-color: #f1f3fa;
  left: 34px;
}

input[data-switch="bool"] + label {
  background-color: #fa5c7c;
}

input:disabled + label {
  opacity: .5;
  cursor: default;
}

input[data-switch="bool"] + label:before, input[data-switch="bool"]:checked + label:before {
  color: #fff !important;
}

input[data-switch="bool"] + label:after {
  background-color: #f1f3fa;
}

input[data-switch="primary"]:checked + label {
  background-color: #212245;
}

input[data-switch="secondary"]:checked + label {
  background-color: #6c757d;
}

input[data-switch="success"]:checked + label {
  background-color: #0acf97;
}

input[data-switch="info"]:checked + label {
  background-color: #39afd1;
}

input[data-switch="warning"]:checked + label {
  background-color: #ffbc00;
}

input[data-switch="danger"]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch="light"]:checked + label {
  background-color: #eef2f7;
}

input[data-switch="dark"]:checked + label {
  background-color: #313a46;
}

body[data-layout-color="dark"] input[data-switch][data-switch="none"] + label {
  background-color: #404954;
}

body[data-layout-color="dark"] input[data-switch] + label:before {
  color: #f1f1f1;
}

body[data-layout-color="dark"] input[data-switch] + label:after {
  background-color: #aab8c5;
}

body[data-layout-color="dark"] input[data-switch]:checked + label:after, body[data-layout-color="dark"] input[data-switch="bool"] + label:after {
  background-color: #404954;
}

body[data-layout-color="dark"] input[data-switch="primary"]:checked + label {
  background-color: #212245;
}

body[data-layout-color="dark"] input[data-switch="secondary"]:checked + label {
  background-color: #ced4da;
}

body[data-layout-color="dark"] input[data-switch="success"]:checked + label {
  background-color: #0acf97;
}

body[data-layout-color="dark"] input[data-switch="info"]:checked + label {
  background-color: #39afd1;
}

body[data-layout-color="dark"] input[data-switch="warning"]:checked + label {
  background-color: #ffbc00;
}

body[data-layout-color="dark"] input[data-switch="danger"]:checked + label {
  background-color: #fa5c7c;
}

body[data-layout-color="dark"] input[data-switch="light"]:checked + label {
  background-color: #464f5b;
}

body[data-layout-color="dark"] input[data-switch="dark"]:checked + label {
  background-color: #f1f1f1;
}

.table-centered th, .table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  height: 30px;
  width: 30px;
}

.table .action-icon {
  color: #98a6ad;
  padding: 0 3px;
  font-size: 1.2rem;
  display: inline-block;
}

.table .action-icon:hover {
  color: #6c757d;
}

.table > :not(caption) > * > * {
  background-color: var(--ct-table-bg) !important;
}

.table.table-bordered tbody {
  border-top: 1px solid;
  border-top-color: inherit;
}

.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}

table.table-hover thead tr:hover > *, table.table-hover tbody tr:hover > *, table tr.table-active, table.table-striped tbody tr:nth-of-type(2n+1) > * {
  --ct-table-accent-bg: #f1f3fa;
}

body[data-layout-color="dark"] table .action-icon {
  color: #ced4da;
}

body[data-layout-color="dark"] table .action-icon:hover {
  color: #dee2e6;
}

body[data-layout-color="dark"] table .table-light {
  --ct-table-bg: #464f5b;
  color: #fff;
  border-color: var(--ct-table-group-separator-color);
}

body[data-layout-color="dark"] table .table-dark {
  --ct-table-bg: #424e5a;
}

body[data-layout-color="dark"] table.table-hover thead tr:hover > *, body[data-layout-color="dark"] table.table-hover tbody tr:hover > *, body[data-layout-color="dark"] table tr.table-active, body[data-layout-color="dark"] table.table-striped tbody tr:nth-of-type(2n+1) > * {
  --ct-table-accent-bg: #404954cc;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  font-weight: 700;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.bg-primary-lighten {
  background-color: #21224540 !important;
}

.bg-secondary-lighten {
  background-color: #6c757d40 !important;
}

.bg-success-lighten {
  background-color: #0acf9740 !important;
}

.bg-info-lighten {
  background-color: #39afd140 !important;
}

.bg-warning-lighten {
  background-color: #ffbc0040 !important;
}

.bg-danger-lighten {
  background-color: #fa5c7c40 !important;
}

.bg-light-lighten {
  background-color: #eef2f740 !important;
}

.bg-dark-lighten {
  background-color: #313a4640 !important;
}

body[data-layout-color="dark"] .bg-primary {
  background-color: #212245 !important;
}

body[data-layout-color="dark"] .bg-primary-lighten {
  background-color: #21224540 !important;
}

body[data-layout-color="dark"] .bg-secondary {
  background-color: #ced4da !important;
}

body[data-layout-color="dark"] .bg-secondary-lighten {
  background-color: #ced4da40 !important;
}

body[data-layout-color="dark"] .bg-success {
  background-color: #0acf97 !important;
}

body[data-layout-color="dark"] .bg-success-lighten {
  background-color: #0acf9740 !important;
}

body[data-layout-color="dark"] .bg-info {
  background-color: #39afd1 !important;
}

body[data-layout-color="dark"] .bg-info-lighten {
  background-color: #39afd140 !important;
}

body[data-layout-color="dark"] .bg-warning {
  background-color: #ffbc00 !important;
}

body[data-layout-color="dark"] .bg-warning-lighten {
  background-color: #ffbc0040 !important;
}

body[data-layout-color="dark"] .bg-danger {
  background-color: #fa5c7c !important;
}

body[data-layout-color="dark"] .bg-danger-lighten {
  background-color: #fa5c7c40 !important;
}

body[data-layout-color="dark"] .bg-light {
  background-color: #464f5b !important;
}

body[data-layout-color="dark"] .bg-light-lighten {
  background-color: #464f5b40 !important;
}

body[data-layout-color="dark"] .bg-dark {
  background-color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .bg-dark-lighten {
  background-color: #f1f1f140 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.text-body {
  color: #6c757d !important;
}

body[data-layout-color="dark"] .text-primary {
  color: #212245 !important;
}

body[data-layout-color="dark"] .text-secondary {
  color: #ced4da !important;
}

body[data-layout-color="dark"] .text-success {
  color: #0acf97 !important;
}

body[data-layout-color="dark"] .text-info {
  color: #39afd1 !important;
}

body[data-layout-color="dark"] .text-warning {
  color: #ffbc00 !important;
}

body[data-layout-color="dark"] .text-danger {
  color: #fa5c7c !important;
}

body[data-layout-color="dark"] .text-light {
  color: #464f5b !important;
}

body[data-layout-color="dark"] .text-dark {
  color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .text-body {
  color: #aab8c5 !important;
}

.border-dashed {
  border-style: dashed !important;
}

body[data-layout-color="dark"] .border-primary {
  border-color: #212245 !important;
}

body[data-layout-color="dark"] .border-secondary {
  border-color: #ced4da !important;
}

body[data-layout-color="dark"] .border-success {
  border-color: #0acf97 !important;
}

body[data-layout-color="dark"] .border-info {
  border-color: #39afd1 !important;
}

body[data-layout-color="dark"] .border-warning {
  border-color: #ffbc00 !important;
}

body[data-layout-color="dark"] .border-danger {
  border-color: #fa5c7c !important;
}

body[data-layout-color="dark"] .border-light {
  border-color: #464f5b !important;
}

body[data-layout-color="dark"] .border-dark {
  border-color: #f1f1f1 !important;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}

.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}

.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: #212245;
  height: 40px;
  width: 40px;
  text-align: center;
  background-color: #21224540;
  border-radius: 3px;
  font-size: 20px;
  line-height: 40px;
  display: inline-block;
}

.dash-item-overlay {
  text-align: left;
  max-width: 350px;
  z-index: 1;
  padding: 20px;
  position: absolute;
  left: 8%;
}

.chart-content-bg {
  background-color: #f9f9fd;
}

.chart-content-border {
  border: 1px solid #eef2f7;
}

.chart-widget-list p {
  border-bottom: 1px solid #f1f3fa;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}

.timeline-alt .timeline-item {
  position: relative;
}

.timeline-alt .timeline-item:before {
  content: "";
  width: 2px;
  z-index: 0;
  background-color: #f1f3fa;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 9px;
}

.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 50%;
  font-size: 12px;
  line-height: 16px;
}

.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid #fff;
  padding: .625rem 0;
  position: relative;
  overflow: hidden;
}

.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}

.inbox-widget .inbox-item .inbox-item-img {
  float: left;
  width: 40px;
  margin-right: 15px;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #343a40;
  margin-bottom: 3px;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #adb5bd;
  margin: 0;
  font-size: .8125rem;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: .6875rem;
  position: absolute;
  top: 10px;
  right: 5px;
}

.tilebox-one i {
  opacity: .3;
  font-size: 2rem;
  position: absolute;
  right: 1.5rem;
}

.toll-free-box i {
  opacity: .4;
  font-size: 4rem;
  position: absolute;
  bottom: -15px;
  left: 0;
  transform: rotate(30deg);
}

.cta-box {
  background-size: cover;
}

.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  padding: 0 15px;
  list-style: none;
}

.conversation-list li {
  margin-bottom: 20px;
}

.conversation-list li .conversation-actions {
  float: right;
  display: none;
}

.conversation-list li:hover .conversation-actions {
  display: block;
}

.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}

.conversation-list .chat-avatar img {
  width: 100%;
  border-radius: 100%;
}

.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}

.conversation-list .ctext-wrap {
  background: var(--ct-chat-secondary-user-bg);
  border-radius: 3px;
  padding: 12px;
  display: inline-block;
  position: relative;
}

.conversation-list .ctext-wrap i {
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  display: block;
  position: relative;
}

.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}

.conversation-list .ctext-wrap:after {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  border-top-color: var(--ct-chat-secondary-user-bg);
  border-width: 6px;
  border-right-color: var(--ct-chat-secondary-user-bg);
  margin-right: -1px;
  position: absolute;
  top: 0;
  left: -11px;
}

.conversation-list .conversation-text {
  float: left;
  width: 70%;
  margin-left: 12px;
  font-size: 13px;
}

.conversation-list .odd .chat-avatar {
  float: right !important;
}

.conversation-list .odd .conversation-text {
  text-align: right;
  margin-right: 12px;
  float: right !important;
  width: 70% !important;
}

.conversation-list .odd .ctext-wrap {
  background-color: var(--ct-chat-primary-user-bg);
}

.conversation-list .odd .ctext-wrap:after {
  border-color: #0000;
  border-left-color: var(--ct-chat-primary-user-bg);
  border-top-color: var(--ct-chat-primary-user-bg);
  left: auto;
  right: -10px;
}

.conversation-list .odd .conversation-actions {
  float: left;
}

.calendar-widget .datepicker-inline, .calendar-widget table {
  width: 100%;
}

.calendar-widget .datepicker-inline tr td, .calendar-widget .datepicker-inline tr th, .calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day, .calendar-widget table tr td, .calendar-widget table tr th, .calendar-widget table tr td.active.day, .calendar-widget table tr td.today.day {
  background-color: #0000 !important;
}

.calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day, .calendar-widget table tr td.active.day, .calendar-widget table tr td.today.day {
  text-shadow: none;
  font-weight: 700;
  color: #fa5c7c !important;
}

.calendar-widget .datepicker-inline tr td.active.day:hover, .calendar-widget .datepicker-inline tr td.today.day:hover, .calendar-widget table tr td.active.day:hover, .calendar-widget table tr td.today.day:hover {
  background-color: #0000 !important;
}

.calendar-widget .datepicker-inline td, .calendar-widget .datepicker-inline th, .calendar-widget table td, .calendar-widget table th {
  height: 44.5px;
}

.calendar-widget .datepicker-inline .datepicker-switch, .calendar-widget .datepicker-inline .prev, .calendar-widget .datepicker-inline .next, .calendar-widget table .datepicker-switch, .calendar-widget table .prev, .calendar-widget table .next {
  color: #212245;
  border-radius: 0;
  font-size: 1.1rem;
  background-color: #2122451a !important;
}

.multi-user a {
  border: 3px solid #f1f3fa;
  border-radius: 50px;
  margin-left: -15px;
}

.multi-user a:first-child {
  margin-left: 0;
}

.card-bg-img {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

body[data-layout-color="dark"] .chart-content-bg {
  background-color: #444e5a;
}

body[data-layout-color="dark"] .chart-content-border {
  border: 1px solid #37404a;
}

body[data-layout-color="dark"] .chart-widget-list p {
  border-bottom: 1px solid #404954;
}

body[data-layout-color="dark"] .timeline-alt .timeline-item:before {
  background-color: #404954;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item {
  border-bottom: 1px solid #4b5662;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-author {
  color: #e3eaef;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-text {
  color: #aab8c5;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-date {
  color: #ced4da;
}

body[data-layout-color="dark"] .multi-user a {
  border: 3px solid #404954;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  color: #adb5bd;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  line-height: calc(2rem - 2px);
  display: block;
}

.social-list-item:hover {
  color: #98a6ad;
  border-color: #98a6ad;
}

body[data-layout-color="dark"] .social-list-item {
  border: 2px solid #aab8c5;
}

body[data-layout-color="dark"] .social-list-item:hover {
  color: #ced4da;
  border-color: #ced4da;
}

.horizontal-steps {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.horizontal-steps:before {
  content: "";
  width: 100%;
  height: .2em;
  background-color: #eef2f7;
  display: block;
  position: absolute;
}

.horizontal-steps .process-line {
  width: 50%;
  height: .2em;
  background-color: #212245;
  display: block;
  position: absolute;
}

.horizontal-steps .horizontal-steps-content {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.horizontal-steps .horizontal-steps-content .step-item {
  height: 8px;
  width: 8px;
  box-sizing: content-box;
  color: #212245;
  z-index: 5;
  background-color: currentColor;
  border: .25em solid #fafbfe;
  border-radius: 50%;
  margin: 0 2em;
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
}

.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}

.horizontal-steps .horizontal-steps-content .step-item:last-child {
  color: #0acf97;
  margin-right: 0;
}

.horizontal-steps .horizontal-steps-content .step-item span {
  white-space: nowrap;
  color: #adb5bd;
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
}

.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  opacity: 0;
  z-index: -1;
  background-color: currentColor;
  border-radius: 50%;
  padding: 1em;
  animation-name: animation-steps-current;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
}

.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: #212245 !important;
}

@keyframes animation-steps-current {
  from {
    opacity: 1;
    transform: translate(-50%, -50%)scale(0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}

body[data-layout-color="dark"] .horizontal-steps:before {
  background-color: #37404a;
}

body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item {
  border: .25em solid var(--ct-body-bg);
}

body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item span {
  color: #aab8c5;
}

#preloader {
  z-index: 9999;
  background-color: #f1f3fa;
  position: fixed;
  inset: 0;
}

#status {
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes bouncing-loader {
  to {
    opacity: .1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  justify-content: center;
  display: flex;
}

.bouncing-loader > div {
  width: 13px;
  height: 13px;
  background: #212245;
  border-radius: 50%;
  margin: 32px 3px;
  animation: bouncing-loader .6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  background: #fa5c7c;
  animation-delay: .2s;
}

.bouncing-loader > div:nth-child(3) {
  background: #0acf97;
  animation-delay: .4s;
}

body[data-layout-color="dark"] #preloader {
  background-color: #404954;
}

.hero-section {
  padding: 80px 0 120px;
  position: relative;
}

.hero-section:after {
  content: " ";
  z-index: -1;
  width: 100%;
  filter: drop-shadow(0 0 8px #000000bf);
  background-position: center;
  background-size: cover;
  border-radius: 0;
  position: absolute;
  top: -400px;
  bottom: 0;
  right: 0;
  transform: skewY(-3deg);
}

.hero-section .hero-title {
  line-height: 42px;
}

body.authentication-bg {
  background-image: url("bg-pattern-light.33d52ada.svg");
  background-position: center;
  background-size: cover;
}

body.authentication-bg .account-pages {
  min-height: 100vh;
  align-items: center;
  display: flex;
}

.authentication-bg.enlarged, .auth-fluid-pages.enlarged, body.auth-fluid-pages[data-leftbar-compact-mode="condensed"] {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  display: flex;
  position: relative;
}

.auth-fluid .auth-fluid-form-box {
  max-width: 480px;
  z-index: 2;
  background-color: var(--ct-auth-bg);
  width: 100%;
  border-radius: 0;
  padding: 3rem 2rem;
  position: relative;
}

.auth-fluid .auth-fluid-right, .auth-fluid .auth-fluid-left {
  color: #fff;
  background-color: #0000004d;
  flex: 1;
  padding: 6rem 3rem;
  position: relative;
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-brand .logo-dark {
  display: block;
}

.auth-brand .logo-light {
  display: none;
}

.auth-user-testimonial {
  margin: 0 auto;
  padding: 0 1.75rem;
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
}

.auth-user-testimonial p.lead {
  max-width: 700px;
  margin: 0 auto 20px;
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 1rem;
  }
}

@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }

  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }

  .auth-fluid .auth-fluid-right {
    display: none;
  }
}

body[data-layout-color="dark"] .auth-brand .logo-dark {
  display: none;
}

body[data-layout-color="dark"] .auth-brand .logo-light {
  display: block;
}

.daterangepicker {
  color: inherit;
  width: 278px;
  max-width: none;
  z-index: 3001;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 7px;
  padding: 0;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
  display: none;
  position: absolute;
  top: 100px;
  left: 20px;
}

.daterangepicker:before, .daterangepicker:after {
  content: "";
  border-bottom-color: #0003;
  display: inline-block;
  position: absolute;
}

.daterangepicker:before {
  border-bottom: 7px solid #ccc;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  top: -7px;
}

.daterangepicker:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  top: -6px;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before, .daterangepicker.openscenter:after {
  width: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  border-bottom: initial;
  border-top: 7px solid #ccc;
  bottom: -7px;
}

.daterangepicker.drop-up:after {
  top: initial;
  border-bottom: initial;
  border-top: 6px solid #fff;
  bottom: -6px;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar, .daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  max-width: 270px;
  display: none;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  padding: 3px;
  display: inline-block;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 4px;
  font-size: 12px;
  line-height: 24px;
}

.daterangepicker .calendar-table {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}

.daterangepicker .calendar-table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  color: inherit;
  background-color: #eee;
  border-color: #0000;
}

.daterangepicker td.week, .daterangepicker th.week {
  color: #ccc;
  font-size: 80%;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  color: #999;
  background-color: #fff;
  border-color: #0000;
}

.daterangepicker td.in-range {
  color: #000;
  background-color: #ebf4f8;
  border-color: #0000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  color: #fff;
  background-color: #357ebd;
  border-color: #0000;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  height: auto;
  cursor: default;
  margin: 0;
  padding: 1px;
  font-size: 12px;
}

.daterangepicker select.monthselect {
  width: 56%;
  margin-right: 2%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  background: #eee;
  border: 1px solid #eee;
  outline: 0;
  margin: 0 auto;
  padding: 2px;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  vertical-align: middle;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 12px;
  display: none;
}

.daterangepicker .drp-selected {
  padding-right: 8px;
  font-size: 12px;
  display: inline-block;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.daterangepicker .ranges li {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 12px;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  color: #fff;
  background-color: #08c;
}

@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.datepicker {
  direction: ltr;
  border-radius: 4px;
  padding: 4px;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  border: 7px solid #0000;
  border-top: 0;
  border-bottom-color: #0003;
  display: inline-block;
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  border: 6px solid #0000;
  border-top: 0;
  border-bottom-color: #fff;
  display: inline-block;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #999;
  border-bottom: 0;
  bottom: -7px;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
}

.datepicker table {
  -webkit-touch-callout: none;
  user-select: none;
  -khtml-user-select: none;
  margin: 0;
}

.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: #0000;
}

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  cursor: pointer;
  background: #eee;
}

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  color: #999;
  cursor: default;
  background: none;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fdd49a", endColorstr= "#fdf59a", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #000;
  background-image: linear-gradient(#fdd49a, #fdf59a);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069\9 ;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  border-radius: 0;
}

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#f3c17a", endColorstr= "#f3e97a", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: linear-gradient(#f3c17a, #f3e97a);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
  border-radius: 0;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9 ;
}

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(gray));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#b3b3b3", endColorstr= "#808080", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#b3b3b3, gray);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: gray;
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666 \9 ;
}

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#08c", endColorstr= "#0044cc", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039 \9 ;
}

.datepicker table tr td span {
  width: 23%;
  height: 54px;
  float: left;
  cursor: pointer;
  border-radius: 4px;
  margin: 1%;
  line-height: 54px;
  display: block;
}

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee;
}

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  color: #999;
  cursor: default;
  background: none;
}

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#08c", endColorstr= "#0044cc", GradientType= 0);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039 \9 ;
}

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden;
}

.datepicker .cw {
  width: 12px;
  vertical-align: middle;
  padding: 0 2px 0 5px;
  font-size: 10px;
}

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  width: auto;
  min-width: 16px;
  height: 18px;
  text-align: center;
  text-shadow: 0 1px #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  width: 250px;
  letter-spacing: normal;
  display: block;
  position: fixed;
  pointer-events: none !important;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  width: 100%;
  color: #fff;
  background-color: #444;
  border-radius: 4px;
  margin: 0 0 5px;
  padding: 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: block;
  position: relative;
  pointer-events: all !important;
}

.jq-toast-single h2, .jq-toast-single .h2 {
  color: inherit;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: inherit;
  letter-spacing: normal;
  background: none;
  margin: 0 0 7px;
}

.jq-toast-single a {
  color: #eee;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
}

.jq-toast-single ul {
  background: none;
  margin: 0 0 0 15px;
  padding: 0;
}

.jq-toast-single ul li {
  letter-spacing: normal;
  background: none;
  margin: 0;
  padding: 0;
  line-height: 17px;
  list-style-type: disc !important;
}

.close-jq-toast-single {
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 3px;
  right: 7px;
}

.jq-toast-loader {
  height: 5px;
  width: 0;
  background: red;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  background-position: 10px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 50px;
}

.jq-icon-info {
  color: #d9edf7;
  background-color: #31708f;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=");
  border-color: #bce8f1;
}

.jq-icon-warning {
  color: #fcf8e3;
  background-color: #8a6d3b;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=");
  border-color: #faebcc;
}

.jq-icon-error {
  color: #f2dede;
  background-color: #a94442;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=");
  border-color: #ebccd1;
}

.jq-icon-success {
  color: #dff0d8;
  background-color: #3c763d;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==");
  border-color: #d6e9c6;
}

.select2-container {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  min-height: 32px;
  user-select: none;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  list-style: none;
  display: inline;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  word-break: keep-all;
  border: none;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  overflow: hidden;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  width: 100%;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  user-select: none;
  padding: 6px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  box-sizing: border-box;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #333;
  background-color: #f1f1f1;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-radius: 0 4px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: linear-gradient(#fff 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  font-weight: bold;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  height: 26px;
  width: 20px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: linear-gradient(#eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: linear-gradient(#fff 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: linear-gradient(#eee 50%, #fff 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: bold;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-radius: 0 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid #0000;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  width: 250px;
  letter-spacing: normal;
  display: block;
  position: fixed;
  pointer-events: none !important;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  width: 100%;
  color: #fff;
  background-color: #444;
  border-radius: 4px;
  margin: 0 0 5px;
  padding: 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: block;
  position: relative;
  pointer-events: all !important;
}

.jq-toast-single h2, .jq-toast-single .h2 {
  color: inherit;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: inherit;
  letter-spacing: normal;
  background: none;
  margin: 0 0 7px;
}

.jq-toast-single a {
  color: #eee;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
}

.jq-toast-single ul {
  background: none;
  margin: 0 0 0 15px;
  padding: 0;
}

.jq-toast-single ul li {
  letter-spacing: normal;
  background: none;
  margin: 0;
  padding: 0;
  line-height: 17px;
  list-style-type: disc !important;
}

.close-jq-toast-single {
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 3px;
  right: 7px;
}

.jq-toast-loader {
  height: 5px;
  width: 0;
  background: red;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  background-position: 10px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 50px;
}

.jq-icon-info {
  color: #d9edf7;
  background-color: #31708f;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=");
  border-color: #bce8f1;
}

.jq-icon-warning {
  color: #fcf8e3;
  background-color: #8a6d3b;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=");
  border-color: #faebcc;
}

.jq-icon-error {
  color: #f2dede;
  background-color: #a94442;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=");
  border-color: #ebccd1;
}

.jq-icon-success {
  color: #dff0d8;
  background-color: #3c763d;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==");
  border-color: #d6e9c6;
}

.bootstrap-timepicker {
  position: relative;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}

.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}

.bootstrap-timepicker .input-group-addon i {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  content: "";
  border-bottom: 7px solid #0003;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  content: "";
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  border-top: 7px solid #999;
  border-bottom: 0;
  bottom: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
}

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget input {
  border-radius: 4px;
}

.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}

.bootstrap-timepicker-widget table td span {
  width: 100%;
}

.bootstrap-timepicker-widget table td a {
  width: 100%;
  color: #333;
  border: 1px solid #0000;
  outline: 0;
  margin: 0;
  padding: 8px 0;
  display: inline-block;
}

.bootstrap-timepicker-widget table td a:hover {
  background-color: #eee;
  border-color: #ddd;
  border-radius: 4px;
  text-decoration: none;
}

.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  text-align: center;
  margin: 0;
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .bootstrap-timepicker, .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}

.bootstrap-touchspin .input-group-btn-vertical {
  height: 100%;
  z-index: 11;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  height: 50%;
  width: 2em;
  text-align: center;
  padding: 0;
  line-height: 1;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px;
  bottom: 0;
}

/*# sourceMappingURL=aquarium.fe8b86d0.css.map */
